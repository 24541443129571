<template>
  <div id="main">
    <div id="banner">
      <img class="banner-t20" :src='require("@/assets/img/product/t20/t20.png")'>
      <p class="banner-series">T20 series</p>
      <p class="banner-title">澜渟T20·新一代<br />盆底肌修复仪</p>
      <p class="banner-subTitle">精准治疗 紧致升级</p>
      <p class="banner-verify">
        南京麦豆健康科技有限公司<br />
        医疗器械注册证编号：苏械注准20242090347<br />
        广告审查批准文号：苏械广审(文)第260411-06435号<br />
        请仔细阅读产品说明书或者在医务人员的指导下购买和使用
      </p>
      <p class="banner-tip-one">仅在中国指定授权机构销售<span>*</span></p>
      <p class="banner-tip-two"><span>*</span>仅涉及T20s型号</p>
    </div>
    <div id="video" class="content-item">
      <div class="video-container">
        <video class="video" ref="myVideo" @pause="playState(false)" @play="playState(true)"
               :src="videoPath" muted autoplay
               :poster="videoPath + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'">
        </video>
        <img v-if="!isVideoPlaying" class="playBtn" @click="playVideo"
             :src='require("@/assets/img/product/t20/play_circle.png")' />
      </div>
    </div>
    <div id="apply" class="content-item">
      <p class="apply-title">澜渟T20 · 新一代 盆底肌修复仪</p>
      <p class="apply-subTitle">治疗盆底症状+阴道松弛</p>
      <p class="apply-content">/ 适用于 /</p>
      <div class="apply-for">
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_1.png")' />
          <span class="for-item-text">治疗压力<br />性尿失禁</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_2.png")' />
          <span class="for-item-text">治疗盆腔<br />器官脱垂</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_3.png")' />
          <span class="for-item-text">缓解慢性<br />盆腔疼痛</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_4.png")' />
          <span class="for-item-text">提升盆底<br />肌肉健康</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_5.png")' />
          <span class="for-item-text">治疗阴道<br />松弛症</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_6.png")' />
          <span class="for-item-text">增强阴道<br />收缩功能</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_7.png")' />
          <span class="for-item-text">促进阴道<br />产后复旧</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_8.png")' />
          <span class="for-item-text">治疗女性<br />性功能障碍</span>
        </div>
      </div>
    </div>
    <div id="technology" class="content-item">
      <span class="technology-title">两大核心技术</span>
      <span class="technology-subTitle">神经肌肉电刺激+肌电生物反馈</span>
      <span class="technology-desc left">神经肌肉电刺激</span>
      <img class="technology-img left" :src='require("@/assets/img/product/t20/technology-left.gif")' />
      <span class="technology-desc right">肌电生物反馈</span>
      <img class="technology-img right" :src='require("@/assets/img/product/t20/technology-right.gif")' />
    </div>
    <div id="system" class="content-item">
      <span class="system-title">双重修复 紧致系统</span>
      <span class="system-subTitle">盆底系统+私密系统</span>
      <img class="system-img left" :src='require("@/assets/img/product/t20/system-img-left.png")' />
      <img class="system-img right" :src='require("@/assets/img/product/t20/system-img-right.png")' />
    </div>
    <div id="pelvic" class="content-item">
      <span class="pelvic-title">第一重·盆底系统</span>
      <span class="pelvic-subTitle">唤活盆底 改善松弛</span>
      <img class="pelvic-img right" :src='require("@/assets/img/product/t20/pelvic-img-right.png")' />
      <img class="pelvic-img left" :src='require("@/assets/img/product/t20/pelvic-img-left.gif")' />
    </div>
    <div id="dimension" class="content-item">
      <span class="dimension-title">多维评估 还原大设备报告</span>
      <span class="dimension-subTitle">6分17秒Glazer评估 国际黄金标准</span>
      <span class="dimension-subTitle extra">6个盆底评估维度、15项评估指标<br />同理麦澜德大设备报告分析</span>
      <img class="dimension-img" :src='require("@/assets/img/product/t20/dimension_img.png")' />
    </div>
    <div id="wireless" class="content-item">
      <div class="wireless-title">无线腹肌监测<br />提升训练有效性</div>
      <div class="wireless-subTitle">独立无线腹肌监测主机 清晰了解腹肌参与情况</div>
      <img class="wireless-img" :src='require("@/assets/img/product/t20/wireless_img.png")' />
    </div>
    <div id="privacy" class="content-item">
      <span class="privacy-title">第二重·私密系统</span>
      <span class="privacy-subTitle">增强收缩 紧致阴道</span>
      <img class="privacy-img left" :src='require("@/assets/img/product/t20/privacy-img-left.png")' />
      <img class="privacy-img right" :src='require("@/assets/img/product/t20/privacy-img-right.png")' />
    </div>
    <div id="assess" class="content-item">
      <div class="assess-title">权威双向评估<br />科学分析阴道状态</div>
      <span class="assess-subTitle">进阶版FSFI量表+6分17秒·Glazer评估双向报告</span>
      <span class="assess-subTitle extra">5个评估维度、21项评估指标，全面评估阴道健康度</span>
      <img class="assess-img" :src='require("@/assets/img/product/t20/assess_img.png")' />
    </div>
    <div id="train" class="content-item">
      <div class="train-title">科学训练<br />提升阴道细胞活力</div>
      <span class="train-subTitle">阴道疲劳智能监测 科学调整训练模板</span>
      <img class="train-img" :src='require("@/assets/img/product/t20/train_img.png")' />
    </div>
    <div id="protect" class="content-item">
      <div class="protect-title">双重护航<br />建立个人专属训练模式</div>
      <span class="protect-subTitle">智能AI顾问动态调整盆底肌/阴道训练方案</span>
      <span class="protect-subTitle extra">GMAA盆底健康顾问终身陪伴服务</span>
      <img class="protect-img" :src='require("@/assets/img/product/t20/protect_img.png")' />
    </div>
    <div id="pattern" class="content-item">
      <span class="pattern-title">五大训练模式<br />科学修复盆底+私密</span>
      <span class="pattern-subTitle">多态模式加护盆底 促进产后阴道修复</span>
      <img class="pattern-img" :src='require("@/assets/img/product/t20/pattern_img.png")' />
    </div>
    <div id="expr" class="content-item">
      <span class="expr-title">无线体验升级 随享无拘无束</span>
      <span class="expr-subTitle">6重设计 操作更简单</span>
      <img class="expr-img" :src='require("@/assets/img/product/t20/expr_img.png")' />
      <div class="expr-item one">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_1.png")' />
        <span class="expr-item-title">内置自体消毒</span>
        <span class="expr-item-desc">UVC灯珠 紫外线消毒</span>
      </div>
      <div class="expr-item two">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_2.png")' />
        <span class="expr-item-title">无线一体化设计</span>
        <span class="expr-item-desc">一键开启 摆脱有线束缚</span>
      </div>
      <div class="expr-item three">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_3.png")' />
        <span class="expr-item-title">充电收纳一体</span>
        <span class="expr-item-desc">保护机身 长效护航</span>
      </div>
      <div class="expr-item four">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_4.png")' />
        <span class="expr-item-title">无线温热探头</span>
        <span class="expr-item-desc">入体更舒适</span>
      </div>
      <div class="expr-item five">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_5.png")' />
        <span class="expr-item-title">温柔震感提醒</span>
        <span class="expr-item-desc">训练异常实时感知</span>
      </div>
      <div class="expr-item six">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_6.png")' />
        <span class="expr-item-title">1000V耐压技术</span>
        <span class="expr-item-desc">科技实力享体验</span>
      </div>
    </div>
    <div id="connect" class="content-item">
      <img class="connect-circle" :src='require("@/assets/img/product/t20/new_circle.gif")' />
      <span class="connect-title">一键连接 智能训练</span>
      <span class="connect-subTitle">产品通过蓝牙连接配套APP<br />享受简单愉悦的智能训练体验</span>
      <img class="connect-img right" :src='require("@/assets/img/product/t20/connect-img-right.png")' />
      <img class="connect-img append" :src='require("@/assets/img/product/t20/connect-img-append.png")' />
      <img class="connect-img left" :src='require("@/assets/img/product/t20/connect-img-left.png")' />
    </div>
    <div id="research" class="content-item">
      <span class="research-title">国际权威验证 专研盆底紧致</span>
      <span class="research-subTitle">《电刺激生物反馈在产后盆底 功能康复中的临床效果评价》</span>
      <span class="research-label">尿失禁临床研究[1]</span>
      <img class="research-img" :src='require("@/assets/img/product/t20/research_img.png")' />
      <span class="research-tip">[1]参考文献：刘盼,李海燕,张庆. 电刺激生物反馈在产后盆底功能康复中的临床效果评价[J]. 生殖医学杂志, 2024, 23 (4): 481-486</span>
    </div>
    <div id="patent" class="content-item">
      <span class="patent-title">国家医疗器械认证及专利</span>
      <span class="patent-subTitle">严苛品质</span>
      <div class="patent-list">
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_1.png")' />
          <span class="patent-item-name">盆底康复治疗仪</span>
          <span class="patent-item-desc">二类医疗器械</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_2.png")' />
          <span class="patent-item-name">发明专利</span>
          <span class="patent-item-desc">ZL 2023 1 1543573.4</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_3.png")' />
          <span class="patent-item-name">发明专利</span>
          <span class="patent-item-desc">ZL 2023 1 0913296.5</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_4.png")' />
          <span class="patent-item-name">发明专利</span>
          <span class="patent-item-desc">ZL 2023 1 1802368.5</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_5.png")' />
          <span class="patent-item-name">外观设计专利</span>
          <span class="patent-item-desc">ZL202330448897.4</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_6.png")' />
          <span class="patent-item-name">外观设计专利</span>
          <span class="patent-item-desc">ZL 2023 3 0481048.9</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/train_prepare_video/${process.env.NODE_ENV === "prod" ? "" : "test/"}website_t20.mp4`,
      isVideoPlaying: false,
      videoHover: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setInterval(() => {
      this.pageScroll();
    }, 1000);
  },
  methods: {
    pageScroll() {
      // 判断 video是不是被 header sh
      const videoDom = document.querySelector('.video-container');
      const headerDom = document.querySelector('#top');
      if (videoDom && headerDom) {
        // videoDom 在 屏幕中间
        if (videoDom.getBoundingClientRect().top + videoDom.getBoundingClientRect().height / 2 > headerDom.getBoundingClientRect().height &&
          window.innerHeight - videoDom.getBoundingClientRect().top > videoDom.getBoundingClientRect().height / 2) {
          // this.$refs.myVideo.play();
          // this.isVideoPlaying = true;
        } else {
          this.$refs.myVideo.pause();
          this.isVideoPlaying = false;
        }
      }
    },
    playState(isPlay) {
      this.isVideoPlaying = isPlay;
      if (isPlay) {
        this.$refs.myVideo.setAttribute("controls", "true");
      } else {
        this.$refs.myVideo.removeAttribute("controls");
      }
    },
    playVideo() {
      this.$refs.myVideo.muted = false;
      this.$refs.myVideo.play();
    }
  }
};
</script>

<style lang="scss" scoped>
$md-color-primary: #5A53CD;

#banner {
  position: relative;
  height: 18rem;
  background: url(../../assets/img/product/t20/banner-bg.png) no-repeat center center;
  background-size: 100% 100%;

  > .banner-t20 {
    position: absolute;
    top: 1rem;
    left: 1.2rem;
    width: 16.65rem;
  }

  > .banner-series {
    position: absolute;
    width: 5.9rem;
    height: 1.35rem;
    border-radius: 0.7rem;
    top: 2.35rem;
    right: 1.6rem;
    line-height: 1.35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-align: center;
    background: linear-gradient(90deg, #7056E5 0, #7090F2 100%);
    color: #FFFFFF;
  }

  > .banner-title {
    position: absolute;
    top: 3.83rem;
    right: 1.6rem;
    line-height: 3rem;
    font-size: 2.4rem;
    font-weight: bold;
    text-align: right;
    color: $md-color-primary;
  }


  > .banner-subTitle {
    position: absolute;
    top: 9.95rem;
    right: 1.6rem;
    line-height: 1;
    font-size: 1.7rem;
  }

  > .banner-verify {
    position: absolute;
    bottom: 1.1rem;
    right: 1.6rem;
    text-align: right;
    line-height: 0.9rem;
    font-size: 0.7rem;
    color: #999999;
  }

  > .banner-tip-one {
    position: absolute;
    left: 1.65rem;
    bottom: 2.2rem;
    color: $md-color-primary;
    font-weight: 500;
    font-size: 0.8rem;

    > span {
      color: $md-color-primary;
      font-weight: 500;
      font-size: 0.8rem;
    }
  }

  > .banner-tip-two {
    position: absolute;
    left: 1.75rem;
    bottom: 1.25rem;
    color: #999999;
    font-weight: 500;
    font-size: 0.6rem;

    > span {
      color: #999999;
      font-weight: 500;
      font-size: 0.6rem;
    }
  }
}

.content-item {
  position: relative;
  padding-top: 0.4rem;

  &:nth-child(odd) {
    background: #FAFBFF;
  }
}

#video {
  height: 20.85rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > .video-container {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > .video {
      width: 34.3rem;
      border-radius: 1rem;
    }

    > .playBtn {
      position: absolute;
      cursor: pointer;
      width: 5rem;
    }
  }
}

#apply {
  height: 40rem;

  > .apply-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: bold;
    text-align: center;
  }

  > .apply-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    color: #666;
  }


  > .apply-content {
    position: absolute;
    top: 10rem;
    left: 15.9rem;
    right: 15.9rem;
    height: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #fff;
    background: $md-color-primary;
    border-radius: 0.4rem;
    white-space: nowrap;
  }

  > .apply-for {
    position: absolute;
    top: 14.8rem;
    left: 3.3rem;
    right: 3.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 2.9rem;
    grid-column-gap: 3.4rem;

    > .for-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .for-item-img {
        width: 5rem;
      }

      > .for-item-text {
        padding-top: 1rem;
        font-size: 1.1rem;
        color: #555;
        line-height: 1.6rem;
        text-align: center;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}

#technology {
  height: 48.3rem;

  > .technology-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .technology-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;
  }

  > .technology-desc {
    position: absolute;
    left: 6.75rem;
    background: $md-color-primary;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    color: #FFFFFF;
    line-height: 1;
    padding: 0.3rem 0.7rem;

    &.left {
      top: 10.7rem;
    }

    &.right {
      top: 28.2rem;
    }
  }

  > .technology-img {
    position: absolute;
    left: 6.75rem;
    width: 24rem;
    border-radius: 1rem;

    &.left {
      top: 12.7rem;
    }

    &.right {
      top: 30.2rem;
    }
  }
}

#system {
  height: 60rem;

  > .system-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .system-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    color: #333333;
    line-height: 1;
  }

  > .system-img {
    position: absolute;

    &.left {
      left: 4.25rem;
      top: 10.95rem;
      width: 29rem;
    }

    &.right {
      left: 6.75rem;
      top: 42.1rem;
      width: 24rem;
    }
  }
}

#pelvic {
  height: 62.2rem;

  > .pelvic-title {
    position: absolute;
    top: 4.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .pelvic-subTitle {
    position: absolute;
    top: 7.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    color: #333333;
    line-height: 1;
  }

  > .pelvic-img {
    position: absolute;

    &.right {
      left: 4.25rem;
      top: 12.5rem;
      width: 29rem;
    }

    &.left {
      left: 6.75rem;
      top: 43.8rem;
      width: 24rem;
      border-radius: 1rem;
    }
  }
}

#dimension {
  height: 40.5rem;

  > .dimension-title {
    position: absolute;
    top: 3.5rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
  }

  > .dimension-subTitle {
    position: absolute;
    top: 6.5rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
    color: #666666;

    &.extra {
      top: 8.2rem;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 1.8rem;
    }
  }


  > .dimension-img {
    position: absolute;
    left: 6.4rem;
    top: 15.5rem;
    width: 24.7rem;
  }
}

#wireless {
  height: 60.75rem;

  > .wireless-title {
    position: absolute;
    top: 3.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.2rem;
  }

  > .wireless-subTitle {
    position: absolute;
    top: 9.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
    color: #666666;
  }

  > .wireless-img {
    position: absolute;
    left: 4.25rem;
    top: 14.15rem;
    width: 29rem;
  }
}

#privacy {
  height: 75.1rem;

  > .privacy-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .privacy-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    color: #333333;
    line-height: 1;
  }

  > .privacy-img {
    position: absolute;

    &.right {
      left: 6.75rem;
      top: 41.25rem;
      width: 24rem;
    }

    &.left {
      left: 4.25rem;
      top: 11.1rem;
      width: 29rem;
    }
  }
}

#assess {
  height: 44.85rem;

  > .assess-title {
    position: absolute;
    top: 3.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 2.2rem;
  }

  > .assess-subTitle {
    position: absolute;
    top: 9.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;

    &.extra {
      top: 11.5rem;
    }
  }

  > .assess-img {
    position: absolute;
    left: 5rem;
    top: 16.3rem;
    width: 26.2rem;
  }
}

#train {
  height: 59rem;

  > .train-title {
    position: absolute;
    top: 3.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.2rem;
  }

  > .train-subTitle {
    position: absolute;
    top: 9.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1;
    color: #666666;
  }

  > .train-img {
    position: absolute;
    left: 4.25rem;
    top: 14.2rem;
    width: 31.2rem;
  }
}

#protect {
  height: 44.35rem;

  > .protect-title {
    position: absolute;
    top: 3.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    line-height: 2.2rem;
  }

  > .protect-subTitle {
    position: absolute;
    top: 9.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;

    &.extra {
      top: 11.5rem;
    }
  }

  > .protect-img {
    position: absolute;
    left: 5.05rem;
    top: 16.1rem;
    width: 27.8rem;
  }
}

#pattern {
  height: 38.1rem;

  > .pattern-title {
    position: absolute;
    top: 3.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.2rem;
  }

  > .pattern-subTitle {
    position: absolute;
    top: 9.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;
  }

  > .pattern-img {
    position: absolute;
    left: 3rem;
    top: 14.2rem;
    width: 31.5rem;
  }
}

#expr {
  height: 53.3rem;

  > .expr-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
  }

  > .expr-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;
  }

  > .expr-img {
    position: absolute;
    top: 24.7rem;
    left: 11.6rem;
    width: 16.3rem;
  }

  > .expr-item {
    position: absolute;
    width: 5.5rem;
    height: 9.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.one {
      top: 24.75rem;
      left: 3.5rem;
    }

    &.two {
      top: 12.5rem;
      left: 8.4rem;
    }

    &.three {
      top: 37.7rem;
      left: 8.4rem;
    }

    &.four {
      top: 12.5rem;
      left: 24.4rem;
    }

    &.five {
      top: 24.75rem;
      left: 29rem;
    }

    &.six {
      top: 37.7rem;
      left: 24.4rem;
    }

    > .expr-item-img {
      width: 5.5rem;
      border-radius: 50%;
    }

    > .expr-item-title {
      font-weight: 500;
      font-size: 1.1rem;
      color: #555555;
      line-height: 1;
      white-space: nowrap;
    }

    > .expr-item-desc {
      font-size: 1rem;
      color: #999999;
      line-height: 1;
      white-space: nowrap;
    }
  }
}

#connect {
  height: 50.1rem;
  overflow: hidden;

  > .connect-circle {
    position: absolute;
    width: 47.8rem;
    left: -4.3rem;
    bottom: -5.2rem;
  }

  > .connect-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
  }

  > .connect-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.8rem;
  }

  > .connect-img {
    position: absolute;

    &.left {
      top: 34.5rem;
      left: 7.4rem;
      width: 21.5rem;
    }

    &.right {
      top: 13.55rem;
      left: 5.45rem;
      width: 27.7rem;
    }

    &.append {
      top: 22.3rem;
      left: 10.35rem;
      width: 14.9rem;
    }
  }
}

#research {
  height: 40.15rem;
  overflow: hidden;

  > .research-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
  }

  > .research-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;
  }

  > .research-label {
    position: absolute;
    top: 9.35rem;
    left: 13.8rem;
    right: 13.8rem;
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    color: #fff;
    background: $md-color-primary;
    border-radius: 0.4rem;
    white-space: nowrap;
  }

  > .research-img {
    position: absolute;
    top: 10.7rem;
    left: -0.7rem;
    width: 100%;
  }

  > .research-tip {
    position: absolute;
    top: 33.4rem;
    left: 3.75rem;
    right: 3.75rem;
    font-size: 0.9rem;
    color: #999999;
    line-height: 1.5rem;
  }
}

#patent {
  height: 53.25rem;

  > .patent-title {
    position: absolute;
    top: 3.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
  }

  > .patent-subTitle {
    position: absolute;
    top: 6.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1;
  }

  > .patent-img {
    position: absolute;
    top: 2.49rem;
    left: 5.6rem;
    width: 8.06rem;
    height: 5.99rem;
  }

  > .patent-list {
    position: absolute;
    top: 10.9rem;
    left: 1.6rem;
    right: 1.6rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.625rem;
    grid-row-gap: 2.5rem;

    > .patent-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .patent-item-img {
        width: 10.35rem;
        height: 13.5rem;
      }

      > .patent-item-name {
        margin-top: 1.4rem;
        font-weight: 500;
        font-size: 1.2rem;
        color: #555555;
        line-height: 1;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }

      > .patent-item-desc {
        margin-top: 0.7rem;
        font-size: 0.9rem;
        color: #999999;
        line-height: 1;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
