<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" v-lazy='require("@/assets/img/technology/banner-bg.jpg")'>
      <p class="banner-title"></p>
      <p class="banner-subTitle"></p>
    </div>
    <div id="detail">
      <div class="detail-item stim" ref="stim">
        <img class="img" v-lazy='require("@/assets/img/technology/stim-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/stim-logo.png")'>
          <p class="title">EMS电刺激技术 修复肌肉功能</p>
          <p class="subTitle">增强肌肉收缩 激发本体感觉</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">应用低频脉冲电流刺激激活受损神经和肌肉，恢复其功能；在增强肌肉收缩力的同时具有恢复本体感觉、镇痛舒缓的作用。</p>
        </div>
      </div>
      <div class="detail-item emg" ref="emg">
        <img class="img" v-lazy='require("@/assets/img/technology/emg-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/emg-logo.png")'>
          <p class="title">肌电生物反馈技术 精准感知盆底活动</p>
          <p class="subTitle">增强肌力 提升控制力</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">通过阴道电极采集盆底肌收缩的肌电信号，将其转化为图形，指导用户正确用力，增强盆底肌收缩协调性和稳定性。</p>
        </div>
      </div>
      <div class="detail-item pressure" ref="pressure">
        <img class="img" v-lazy='require("@/assets/img/technology/pressure-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/pressure-logo.png")'>
          <p class="title">压力生物反馈技术 可视化盆底活动</p>
          <p class="subTitle">降低肌张力 促进主动训练</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">通过压力探头检测盆底肌肉压力信号变化，引导用户进行主动收缩锻炼，强化盆底肌力，牵张放松训练盆底肌。</p>
        </div>
      </div>
      <div class="detail-item ai" ref="ai">
        <img class="img" v-lazy='require("@/assets/img/technology/ai-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/ai-logo.png")'>
          <p class="title">AI大数据技术 定制专属方案</p>
          <p class="subTitle">千人千面 专属方案</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">根据不同的盆底受损程度，推送AI定制化方案，智能生成专属的治疗参数，满足个性化修复需求。</p>
        </div>
      </div>
      <div class="detail-item pattern" ref="pattern">
        <img class="img" v-lazy='require("@/assets/img/technology/pattern-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/pattern-logo.png")'>
          <p class="title">八大训练模式 赋能科学治疗体系</p>
          <p class="subTitle">丰富有趣 科学修复</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">“主被动结合”的科学治疗体系，训练模式丰富多样，趣味十足。疗程化方案科学编排，循序渐进，稳步修复。</p>
        </div>
      </div>
      <div class="detail-item monitor" ref="monitor">
        <img class="img" v-lazy='require("@/assets/img/technology/monitor-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/monitor-logo.png")'>
          <p class="title">智能监测系统 助力安心训练</p>
          <p class="subTitle">安全提醒 辅助训练</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">集合智能传感技术、智能数据算法，监测智能硬件与肌肉状态，实时反馈引导，引导正确训练；及时提示预警，保护安全训练。</p>
        </div>
      </div>
      <div class="detail-item ota" ref="ota">
        <img class="img" v-lazy='require("@/assets/img/technology/ota-img.png")'>
        <div class="info">
          <img class="logo" v-lazy='require("@/assets/img/technology/ota-logo.png")'>
          <p class="title">OTA技术 畅享一键迭代</p>
          <p class="subTitle">摆脱空间限制 简单高效远程固件升级</p>
          <div class="vi-symbol symbol"></div>
          <p class="desc">将高端新能源汽车升级技术应用于家用设备，通过OTA远程升级方式（Over-the-Air），实现一键功能进化，提供便捷、实用的智能交互体验。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style lang="scss" scoped>
$md-symbol-img-path: $md-symbol-img-path-before;

#banner {
  position: relative;
  height: 18rem;

  > .banner-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .banner-title {
    position: absolute;
    z-index: 2;
    top: 2.3rem;
    left: 19.3rem;
    line-height: 3rem;
    font-size: 2.2rem;
    color: #fff;
  }

  > .banner-subTitle {
    position: absolute;
    z-index: 2;
    top: 5.3rem;
    left: 19.3rem;
    line-height: 2.6rem;
    font-size: 1.9rem;
    color: #fff;
  }
}

#detail {
  position: relative;
  overflow-x: hidden;

  > .detail-item {
    position: relative;
    padding-bottom: 0.4rem;
    padding-right: 1.8rem;
    padding-left: 1.8rem;

    > .img {
      position: absolute;
    }

    > .info {
      position: relative;
      padding-left: 0.2rem;
      padding-right: 0.2rem;

      > .logo {
        position: absolute;
      }

      > .title {
        position: absolute;
        top: 3rem;
        width: 100%;
        text-align: center;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 2.5rem;
      }

      > .subTitle {
        position: absolute;
        top: 5.9rem;
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        line-height: 2rem;
      }

      > .symbol {
        position: absolute;
        top: 8.8rem;
        left: 15.5rem;
        width: 2.5rem;
        height: 0.511rem;
        background-image: url('../' + $md-symbol-img-path);
      }

      > .desc {
        position: absolute;
        width: 100%;
        top: 9.9rem;
        text-align: left;
        font-size: 1.2rem;
        color: #666666;
        line-height: 1.8rem;
      }
    }
  }

  > .detail-item.stim {
    background: #ffffff;
    height: 34.8rem;

    > .img {
      top: 14.7rem;
      width: 34rem;
      height: 18.9rem;
    }

    > .info {
      > .logo {
        top: 2.9rem;
        left: 14.2rem;
        width: 4.97rem;
        height: 5rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }

  > .detail-item.emg {
    background: #FAFBFF;
    height: 49.6rem;

    > .img {
      top: 16.5rem;
      width: 33.9rem;
      height: 30rem;
    }

    > .info {
      > .logo {
        top: 3.1rem;
        left: 14.2rem;
        width: 5rem;
        height: 4.55rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }

  > .detail-item.pressure {
    background: #ffffff;
    height: 49.1rem;

    > .img {
      top: 16.3rem;
      width: 33rem;
      height: 29.3rem;
    }

    > .info {
      > .logo {
        top: 3.2rem;
        left: 14.2rem;
        width: 5rem;
        height: 4.89rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }

  > .detail-item.ai {
    background: #FAFBFF;
    height: 44.6rem;

    > .img {
      top: 16.7rem;
      width: 33.9rem;
      height: 26rem;
    }

    > .info {
      > .logo {
        top: 2.8rem;
        left: 14.2rem;
        width: 4.6rem;
        height: 5rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }

  > .detail-item.pattern {
    background: #ffffff;
    height: 44.8rem;

    > .img {
      top: 15.5rem;
      width: 33.9rem;
      height: 26rem;
    }

    > .info {
      > .logo {
        top: 2.9rem;
        left: 14.2rem;
        width: 5rem;
        height: 5rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }

  > .detail-item.monitor {
    background: #FAFBFF;
    height: 45.4rem;

    > .img {
      top: 17.2rem;
      width: 33.9rem;
      height: 26rem;
    }

    > .info {
      > .logo {
        top: 2.8rem;
        left: 14.2rem;
        width: 4.11rem;
        height: 5rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }

  > .detail-item.ota {
    background: #ffffff;
    height: 43.4rem;

    > .img {
      top: 16.3rem;
      width: 33.9rem;
      height: 26rem;
    }

    > .info {
      > .logo {
        top: 3rem;
        left: 14.2rem;
        width: 4.28rem;
        height: 5rem;
      }

      > .title {
      }

      > .subTitle {
      }

      > .symbol {
      }

      > .desc {
      }
    }
  }
}
</style>
