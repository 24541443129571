<template>
    <div id='main'>
        <p class="title">特别提示</p>
        <p class="section">
            南京麦豆健康科技有限公司（以下简称“麦豆”）在此特别提醒您（用户）在注册成为用户之前，请认真阅读本《用户服务协议》
            （以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，
            否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。
        </p>
        <p class="section">
            本协议约定麦豆与用户之间关于“imove”软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。
            本协议可由麦豆随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网站查阅最新版协议条款。
            在麦豆修改协议条款后，如果用户不接受修改后的条款，请立即停止使用麦豆提供的服务，用户继续使用麦豆提供的服务将被视为接受修改后的协议。
        </p>
        <p class="subTitle">一、帐号注册</p>
        <p class="section">
            1、用户在使用本服务前需要注册一个“imove”帐号。“imove”帐号应当使用手机号码绑定注册，请用户使用尚未与“imove”帐号绑定的手机号码，
            以及未被麦豆根据本协议封禁的手机号码注册“imove”帐号。麦豆可以根据用户需求或产品需要对帐号注册和绑定的方式进行变更，
            而无须事先通知用户。
        </p>
        <p class="section">
            2、“imove”是基于地理位置的移动社交产品，用户注册时应当授权麦豆公开及使用其地理位置信息方可成功注册“imove”帐号。
            故用户完成注册即表明用户同意麦豆提取、公开及使用用户的地理位置信息。如用户需要终止向其他用户公开其地理位置信息，可自行设置为隐身状态。
        </p>
        <p class="section">
            3、鉴于“imove”帐号的绑定注册方式，您同意麦豆在注册时将使用您提供的手机号码或自动提取您的手机设备识别码等信息用于注册。
        </p>
        <p class="section">
            4、在用户注册及使用本服务时，麦豆需要搜集能识别用户身份的个人信息以便麦豆可以在必要时联系用户，或为用户提供更好的使用体验。
            麦豆搜集的信息包括但不限于用户的姓名、性别、年龄、出生日期、身份证号、地址、学校情况、公司情况、所属行业、兴趣爱好、
            常出没的地方、个人说明；麦豆同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
        </p>
        <p class="subTitle">二、服务内容</p>
        <p class="section">
            1、本服务的具体内容由麦豆根据实际情况提供，包括但不限于授权用户通过其帐号进行接收方案，教程学习，方案训练，凯格尔训练，
            游戏训练，方案计划，方案列表，训练记录，盆底知识，即时通讯等。麦豆可以对其提供的服务予以变更，
            且麦豆提供的服务内容可能随时变更；用户将会收到麦豆关于服务变更的通知。
        </p>
        <p class="section">
            2、麦豆提供的服务是免费服务。
        </p>
        <p class="subTitle">三、用户个人隐私信息保护</p>
        <p class="section">
            1、用户在注册帐号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）
            规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
        </p>
        <p class="section">
            2、个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址、
            用户聊天记录。非个人隐私信息是指用户对本服务的操作状态以及使用习惯等明确且客观反映在麦豆服务器端的基本记录信息、
            个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。
        </p>
        <p class="section">
            3、尊重用户个人隐私信息的私有性是麦豆的一贯制度，麦豆将采取技术措施和其他必要措施，确保用户个人隐私信息安全，
            防止在本服务中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者麦豆发现存在发生前述情形的可能时，将及时采取补救措施。
        </p>
        <p class="section">
            4、麦豆未经用户同意不向任何第三方公开、透露用户个人隐私信息。但以下特定情形除外：<br/>
            (1) 麦豆根据法律法规规定或有权机关的指示提供用户的个人隐私信息；<br/>
            (2) 由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因麦豆原因导致的个人隐私信息的泄露；<br/>
            (3) 用户自行向第三方公开其个人隐私信息；<br/>
            (4) 用户与麦豆及合作单位之间就用户个人隐私信息的使用公开达成约定，麦豆因此向合作单位公开用户个人隐私信息；<br/>
            (5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。
        </p>
        <p class="section">
            5、用户同意麦豆可在以下事项中使用用户的个人隐私信息：<br/>
            (1) 麦豆向用户及时发送重要通知，如软件更新、本协议条款的变更；<br/>
            (2) 麦豆内部进行审计、数据分析和研究等，以改进麦豆的产品、服务和与用户之间的沟通；<br/>
            (3) 依本协议约定，麦豆管理、审查用户信息及进行处理措施；<br/>
            (4) 适用法律法规规定的其他事项。
        </p>
        <p class="section">
            除上述事项外，如未取得用户事先同意，麦豆不会将用户个人隐私信息使用于任何其他用途。
        </p>
        <p class="section">
            6、麦豆重视对未成年人个人隐私信息的保护。麦豆将依赖用户提供的个人信息判断用户是否为未成年人。
            任何18岁以下的未成年人注册帐号或使用本服务应事先取得家长或其法定监护人（以下简称“监护人”）的书面同意。
            除根据法律法规的规定及有权机关的指示披露外，麦豆不会使用或向任何第三方透露未成年人的聊天记录及其他个人隐私信息。
            除本协议约定的例外情形外，未经监护人事先同意，麦豆不会使用或向任何第三方透露未成年人的个人隐私信息。
            任何18岁以下的用户不得下载和使用麦豆通过imove软件提供的网络游戏。
        </p>
        <p class="section">
            7、用户确认，其地理位置信息为非个人隐私信息，用户成功注册“imove”帐号视为确认授权麦豆提取、公开及使用用户的地理位置信息。
            用户地理位置信息将作为用户公开资料之一，由麦豆向其他用户公开。如用户需要终止向其他用户公开其地理位置信息，
            可随时自行设置为隐身状态。
        </p>
        <p class="section">
            8、为了改善麦豆的技术和服务，向用户提供更好的服务体验，麦豆或可会自行收集使用或向第三方提供用户的非个人隐私信息。
        </p>
        <p class="subTitle">四、内容规范</p>
        <p class="section">
            1、本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于帐号头像、名称、
            用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，
            以及其他使用帐号或本服务所产生的内容。
        </p>
        <p class="section">
            2、用户不得利用“imove”帐号或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br/>
            (1) 反对宪法所确定的基本原则的；<br/>
            (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br/>
            (3) 损害国家荣誉和利益的；<br/>
            (4) 煽动民族仇恨、民族歧视，破坏民族团结的；<br/>
            (5) 破坏国家宗教政策，宣扬邪教和封建迷信的；<br/>
            (6) 散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
            (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br/>
            (8) 侮辱或者诽谤他人，侵害他人合法权益的；<br/>
            (9) 不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、
            道德风尚底线和信息真实性底线的“七条底线”要求的；<br/>
            (10) 含有法律、行政法规禁止的其他内容的信息。
        </p>
        <p class="section">
            3、用户不得利用“imove”帐号或本服务制作、上载、复制、发布、传播如下干扰“imove”正常运营，
            以及侵犯其他用户或第三方合法权益的内容：<br/>
            (1) 含有任何性或性暗示的；<br/>
            (2) 含有辱骂、恐吓、威胁内容的；<br/>
            (3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br/>
            (4) 涉及他人隐私、个人信息或资料的；<br/>
            (5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br/>
            (6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
        </p>
        <p class="subTitle">五、使用规则</p>
        <p class="section">
            1、用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表麦豆的观点、立场或政策，麦豆对此不承担任何责任。
        </p>
        <p class="section">
            2、用户不得利用“imove”帐号或本服务进行如下行为：<br/>
            (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br/>
            (2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br/>
            (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br/>
            (4) 利用技术手段批量建立虚假帐号的；<br/>
            (5) 利用“imove”帐号或本服务从事任何违法犯罪活动的；<br/>
            (6) 制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br/>
            (7) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“imove”正常运营或麦豆未明示授权的行为。<br/>
        </p>
        <p class="section">
            3、用户须对利用“imove”帐号或本服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，
            与用户所传播的信息相关的任何法律责任由用户自行承担，与麦豆无关。如因此给麦豆或第三方造成损害的，用户应当依法予以赔偿。
        </p>
        <p class="section">
            4、麦豆提供的服务中可能包括广告，用户同意在使用过程中显示麦豆和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，
            用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，麦豆不承担任何责任。
        </p>
        <p class="subTitle">六、账户管理</p>
        <p class="section">
            1、“imove”帐号的所有权归麦豆所有，用户完成申请注册手续后，获得“imove”帐号的使用权，该使用权仅属于初始申请注册人，
            禁止赠与、借用、租用、转让或售卖。麦豆因经营需要，有权回收用户的“imove”帐号。
        </p>
        <p class="section">
            2、用户可以更改、删除“imove”帐户上的个人资料、注册信息及传送内容等，但需注意，
            删除有关信息的同时也会删除用户储存在系统中的文字和图片。用户需承担该风险。
        </p>
        <p class="section">
            3、用户有责任妥善保管注册帐号信息及帐号密码的安全，因用户保管不善可能导致遭受盗号或密码失窃，责任由用户自行承担。
            用户需要对注册帐号以及密码下的行为承担法律责任。用户同意在任何情况下不使用其他用户的帐号或密码。
            在用户怀疑他人使用其帐号或密码时，用户同意立即通知麦豆。
        </p>
        <p class="section">
            4、用户应遵守本协议的各项条款，正确、适当地使用本服务，如因用户违反本协议中的任何条款，
            麦豆在通知用户后有权依据协议中断或终止对违约用户“imove”帐号提供服务。同时，麦豆保留在任何时候收回“imove”帐号、用户名的权利。
        </p>
        <p class="section">
            5、如用户注册“imove”帐号后一年不登录，通知用户后，麦豆可以收回该帐号，以免造成资源浪费，由此造成的不利后果由用户自行承担。
        </p>
        <p class="subTitle">七、上门预约</p>
        <p class="section">
            1、用户发起上门预约服务时，需仔细确认预约服务的名称、价格、数量、说明、注意事项、联系地址、电话、联系人等信息。
            联系人与用户本人不一致的，联系人的行为和意思表示视为用户的行为和意思表示，
            用户应对联系人的行为及意思表示的法律后果承担连带责任。
        </p>
        <p class="section">
            2、由于市场变化及各种以合理商业努力难以控制的因素的影响，麦豆无法保证您提交的预约信息中希望预订的服务都能提供；
            如您拟预订的服务，发生无法提供的情形，您有权取消预约。
        </p>
        <p class="subTitle">八、数据储存</p>
        <p class="section">
            1、麦豆不对用户在本服务中相关数据的删除或储存失败负责。
        </p>
        <p class="section">
            2、麦豆可以根据实际情况自行决定用户在本服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。
            用户可根据自己的需要自行备份本服务中的相关数据。
        </p>
        <p class="section">
            3、如用户停止使用本服务或本服务终止，麦豆可以从服务器上永久地删除用户的数据。本服务停止、终止后，
            麦豆没有义务向用户返还任何数据。
        </p>
        <p class="subTitle">九、风险承担</p>
        <p class="section">
            1、用户理解并同意，“imove”仅为用户提供信息分享、传送及获取的平台，用户必须为自己注册帐号下的一切行为负责，
            包括用户所传送的任何内容以及由此产生的任何后果。用户应对“imove”及本服务中的内容自行加以判断，
            并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
            麦豆无法且不会对因用户行为而导致的任何损失或损害承担责任。<br/>
            如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向麦豆举报或投诉，麦豆将依本协议约定进行处理。
        </p>
        <p class="section">
            2、用户理解并同意，因业务发展需要，麦豆保留单方面对本服务的全部或部分服务内容变更、暂停、终止或撤销的权利，用户需承担此风险。
        </p>
        <p class="subTitle">十、知识产权声明</p>
        <p class="section">
            1、除本服务中涉及广告的知识产权由相应广告商享有外，麦豆在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、
            图表等）的知识产权均归麦豆所有，但用户在使用本服务前对自己发布的内容已合法取得知识产权的除外。
        </p>
        <p class="section">
            2、除另有特别声明外，麦豆提供本服务时所依托软件的著作权、专利权及其他知识产权均归麦豆所有。
        </p>
        <p class="section">
            3、麦豆在本服务中所涉及的图形、文字或其组成，以及其他麦豆标志及产品、服务名称（以下统称“麦豆标识”），
            其著作权或商标权归麦豆所有。未经麦豆事先书面同意，用户不得将麦豆标识以任何方式展示或使用或作其他处理，
            也不得向他人表明用户有权展示、使用、或其他有权处理麦豆标识的行为。
        </p>
        <p class="section">
            4、上述及其他任何麦豆或相关广告商依法拥有的知识产权均受到法律保护，未经麦豆或相关广告商书面许可，
            用户不得以任何形式进行使用或创造相关衍生作品。
        </p>
        <p class="subTitle">十一、法律责任</p>
        <p class="section">
            1、如果麦豆发现或收到他人举报或投诉用户违反本协议约定的，麦豆有权不经通知随时对相关内容，包括但不限于用户资料、
            聊天记录进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁、设备封禁、功能封禁的处罚，且通知用户处理结果。
        </p>
        <p class="section">
            2、用户理解并同意，麦豆有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，
            并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
        </p>
        <p class="section">
            3、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，
            用户应当赔偿麦豆与合作公司、关联公司，并使之免受损害。
        </p>
        <p class="subTitle">十二、不可抗力及其他免责事由</p>
        <p class="section">
            1、用户理解并确认，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、
            不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、
            瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，麦豆将努力在第一时间与相关单位配合，
            及时进行修复，但是由此给用户或第三方造成的损失，麦豆及合作单位在法律允许的范围内免责。
        </p>
        <p class="section">
            2、本服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，
            如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，
            威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，
            要注意加强密码保护，以免遭致损失和骚扰。
        </p>
        <p class="section">
            3、用户理解并确认，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、
            互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，麦豆不承担任何责任。
        </p>
        <p class="section">
            4、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，
            或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，麦豆不承担任何责任。
        </p>
        <p class="section">
            5、用户理解并确认，麦豆需要定期或不定期地对“imove”平台或相关的设备进行检修或者维护，
            如因此类情况而造成服务在合理时间内的中断，麦豆无需为此承担任何责任，但麦豆应事先进行通告。
        </p>
        <p class="section">
            6、麦豆依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成麦豆的义务或承诺，
            麦豆不能保证及时发现违法违规或违约行为或进行相应处理。
        </p>
        <p class="section">
            7、用户理解并确认，对于麦豆向用户提供的下列产品或者服务的质量缺陷及其引发的任何损失，麦豆无需承担任何责任：<br/>
            (1) 麦豆向用户免费提供的服务；<br/>
            (2) 麦豆向用户赠送的任何产品或者服务。
        </p>
        <p class="section">
            8、在任何情况下，麦豆均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，
            包括因用户使用“imove”或本服务而遭受的利润损失，承担责任（即使麦豆已被告知该等损失的可能性亦然）。
            尽管本协议中可能含有相悖的规定，麦豆对用户承担的全部责任，无论因何原因或何种行为方式，
            始终不超过用户因使用麦豆提供的服务而支付给麦豆的费用(如有)。
        </p>
        <p class="subTitle">十三、服务的变更、中断、终止</p>
        <p class="section">
            1、鉴于网络服务的特殊性，用户同意麦豆有权随时变更、中断或终止部分或全部的服务。麦豆变更、中断或终止的服务，
            麦豆应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务。
        </p>
        <p class="section">
            2、如发生下列任何一种情形，麦豆有权变更、中断或终止向用户提供的免费服务，而无需对用户或任何第三方承担任何责任：<br/>
            (1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；<br/>
            (2) 用户违反相关法律法规或本协议的约定；<br/>
            (3) 按照法律规定或有权机关的要求；<br/>
            (4) 出于安全的原因或其他必要的情形。
        </p>
        <p class="subTitle">十四、其他</p>
        <p class="section">
            1、麦豆郑重提醒用户注意本协议中免除麦豆责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。
            未成年人应在法定监护人的陪同下阅读本协议。
        </p>
        <p class="section">
            2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和麦豆之间发生任何纠纷或争议，首先应友好协商解决，
            协商不成的，用户同意将纠纷或争议提交麦豆住所地有管辖权的人民法院管辖。
        </p>
        <p class="section">
            3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
        </p>
    </div>
</template>

<script>
export default {
    mounted() {
        document.getElementById('top').style.display = 'none'
        document.getElementById('menuDetail').style.display = 'none'
        document.getElementById('content').style.paddingTop = 0
        document.getElementById('bottom').style.display = 'none'
        document.title = 'imove用户协议'
    }
}
</script>

<style lang="scss" scoped>
    #main {
        margin: 1.2rem 1.2rem 3.6rem 1.2rem;
        >.title {
            font-weight: bold;
        }
        >.subTitle {
            margin-top: 1.2rem;
            font-size: 1.4rem; font-weight: bold;
        }
        >.section {
            line-height: 2.2rem;
            font-size: 1.4rem;
        }
    }
</style>