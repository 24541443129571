<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" v-lazy='require("@/assets/img/app/banner-bg.png")'>
      <p class="banner-title"></p>
      <p class="banner-subTitle"></p>
      <button class="wrightin-download" @click="downloadWrightin()">
        <img class="banner-wrightin-img" v-lazy='require("@/assets/img/app/banner-wrightin.png")'>
        <span class="banner-wrightin-text">凯格尔训练免费指导平台</span>
        <span class="text">澜渟用户版App</span>
        <img class="img" v-lazy='require("@/assets/img/app/banner-download.png")'>
      </button>
      <button class="doctor-download" @click="downloadDoctor()">
        <img class="banner-doctor-img" v-lazy='require("@/assets/img/app/banner-doctor.png")'>
        <span class="banner-doctor-text">盆底康复医生成长进阶之选</span>
        <img class="img" v-lazy='require("@/assets/img/app/banner-download.png")'>
        <span class="text">澜渟医生版App</span>
      </button>
    </div>
    <div id="detail">
      <div class="detail-item trainW">
        <img class="img" v-lazy='require("@/assets/img/app/detail-trainW.png")'>
        <p class="title">训练</p>
        <p class="desc">产后康复训练<br />塑造产后更美的你</p>
        <div class="vi-symbol symbol"></div>
      </div>
      <div class="detail-item friendW">
        <img class="img" v-lazy='require("@/assets/img/app/detail-friendW.png")'>
        <p class="title">盆友圈</p>
        <p class="desc">盆底肌训练圈子<br />见证每一天的进步</p>
        <div class="vi-symbol symbol"></div>
      </div>
      <div class="detail-item baike">
        <img class="img" v-lazy='require("@/assets/img/app/detail-baike.png")'>
        <p class="title">百科</p>
        <p class="desc">盆底界小百科<br />轻松获取各种盆底知识</p>
        <div class="vi-symbol symbol"></div>
      </div>
      <div class="detail-item meetingD">
        <img class="img" v-lazy='require("@/assets/img/app/detail-meetingD.png")'>
        <p class="title">会议吧</p>
        <p class="desc">妇产医生进修学习<br />参会考证晋升有道</p>
        <div class="vi-symbol symbol"></div>
      </div>
      <div class="detail-item collegeD">
        <img class="img" v-lazy='require("@/assets/img/app/detail-collegeD.png")'>
        <p class="title">极致学院</p>
        <p class="desc">海量盆底干货课程学习<br />盆底学术大咖直播分享</p>
        <div class="vi-symbol symbol"></div>
      </div>

      <img class="detail-circle" style="top:5rem;left:0;"
           v-lazy='require("@/assets/img/app/bg_left.png")'>
      <img class="detail-circle-right" style="top:31.3rem;right:0;"
           v-lazy='require("@/assets/img/app/bg_right.png")'>
      <img class="detail-circle" style="top:58.3rem;left:0;"
           v-lazy='require("@/assets/img/app/bg_left.png")'>
      <img class="detail-circle-right" style="top:86.3rem;right:0;"
           v-lazy='require("@/assets/img/app/bg_right.png")'>
      <img class="detail-circle" style="top:113.4rem;left:0;"
           v-lazy='require("@/assets/img/app/bg_left.png")'>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    /**
     页面跳转-下载澜渟
     @param
     @return
     */
    downloadWrightin() {
      window.location.href = this.$request.user + "/apps/wrightin";
    },
    /**
     页面跳转-下载澜渟医生
     @param
     @return
     */
    downloadDoctor() {
      window.location.href = this.$request.doc + "/apps/wrightindoc";
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 25rem;

  > .banner-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > .banner-title {
    position: absolute;
    z-index: 2;
    top: 2.3rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 3rem;
    font-size: 2.2rem;
    color: #fff;
  }

  > .banner-subTitle {
    position: absolute;
    z-index: 2;
    top: 5.7rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 2rem;
    font-size: 1.4rem;
    color: #fff;
  }

  > .wrightin-download {
    position: absolute;
    z-index: 2;
    top: 15.9rem;
    left: 2.7rem;
    background: #fff;
    width: 15rem;
    height: 7rem;
    border-radius: 0.8rem;

    > .banner-wrightin-img {
      position: absolute;
      z-index: 2;
      top: 0.7rem;
      left: 1.2rem;
      width: 3.3rem;
      height: 3.3rem;
    }

    > .banner-wrightin-text {
      position: absolute;
      z-index: 2;
      top: .6rem;
      left: 5.1rem;
      right: 1.5rem;
      line-height: 1.7rem;
      font-size: 1.2rem;
      text-align: left;
      color: $md-color-primary;
    }

    > .text {
      position: absolute;
      left: 1.5rem;
      bottom: 0.7rem;
      line-height: 1.8rem;
      font-size: 1.3rem;
      color: #333;
    }

    > .img {
      position: absolute;
      right: 1.5rem;
      bottom: 0.7rem;
      width: 1.8rem;
    }
  }

  > .doctor-download {
    position: absolute;
    z-index: 2;
    top: 15.9rem;
    left: 19.8rem;
    background: #fff;
    width: 15rem;
    height: 7rem;
    border-radius: 0.8rem;

    > .banner-doctor-img {
      position: absolute;
      z-index: 2;
      top: .7rem;
      left: .9rem;
      width: 3.3rem;
      height: 3.3rem;
    }

    > .banner-doctor-text {
      position: absolute;
      z-index: 2;
      top: .6rem;
      left: 4.6rem;
      right: .8rem;
      line-height: 1.7rem;
      font-size: 1.2rem;
      text-align: left;
      color: #29CBD3;
    }

    > .text {
      position: absolute;
      left: 1.5rem;
      bottom: 0.7rem;
      line-height: 1.8rem;
      font-size: 1.3rem;
      color: #333;
    }

    > .img {
      position: absolute;
      right: 1.5rem;
      bottom: 0.7rem;
      width: 1.8rem;
    }
  }
}

#detail {
  padding-top: 5rem;
  position: relative;

  > .detail-circle {
    position: absolute;
    z-index: -2;
    width: 26.8rem;
    height: 23.8rem;
  }

  > .detail-circle-right {
    position: absolute;
    z-index: -2;
    width: 27.5rem;
    height: 23.9rem;
  }

  > .detail-item {
    position: relative;

    > .img {
      position: absolute;
    }

    > .title {
      position: absolute;
      line-height: 2rem;
      font-size: 1.8rem;
      font-weight: bold;
    }

    > .desc {
      position: absolute;
      line-height: 2rem;
      font-size: 1.4rem;
    }

    > .symbol {
      position: absolute;
      width: 2.5rem;
      height: 0.511rem;
    }
  }

  > .detail-item.trainW {
    height: 26.3rem;

    > .img {
      left: 2.4rem;
      width: 16.2rem;
      height: 20.9rem;
    }

    > .title {
      top: 5.1rem;
      left: 23.3rem;
    }

    > .desc {
      top: 7.9rem;
      left: 23.3rem;
    }

    > .symbol {
      top: 12.7rem;
      left: 23.3rem;
    }
  }

  > .detail-item.friendW {
    height: 26.8rem;

    > .img {
      top: 0;
      left: 16.7rem;
      width: 18.8rem;
      height: 20.9rem;
    }

    > .title {
      left: 2.8rem;
      top: 5.1rem;
    }

    > .desc {
      left: 2.8rem;
      top: 7.9rem;
    }

    > .symbol {
      left: 2.8rem;
      top: 12.7rem;
    }
  }

  > .detail-item.baike {
    height: 27.5rem;

    > .img {
      top: 0;
      left: 1.2rem;
      width: 18.7rem;
      height: 20.8rem;
    }

    > .title {
      left: 21.7rem;
      top: 5rem;
    }

    > .desc {
      left: 21.7rem;
      top: 7.8rem;
    }

    > .symbol {
      left: 21.7rem;
      top: 12.6rem;
    }
  }

  > .detail-item.meetingD {
    height: 27.7rem;

    > .img {
      left: 16.6rem;
      width: 18.6rem;
      height: 20.9rem;
    }

    > .title {
      left: 2.8rem;
      top: 5rem;
    }

    > .desc {
      left: 2.8rem;
      top: 7.8rem;
    }

    > .symbol {
      left: 2.8rem;
      top: 12.6rem;
    }
  }

  > .collegeD {
    height: 28.5rem;

    > .img {
      left: 1.1rem;
      width: 18.6rem;
      height: 20.9rem;
    }

    > .title {
      top: 5.1rem;
      left: 21.5rem;
    }

    > .desc {
      top: 7.9rem;
      left: 21.5rem;
    }

    > .symbol {
      left: 21.5rem;
      top: 12.7rem;
    }
  }
}
</style>
