<template>
  <div id="main">
    <div id="banner" @click="goBack">
      澜渟品牌资讯中心
    </div>
    <div id="article" v-if="detail.id">
      <div class="title">{{ detail.title }}</div>
      <div class="date">发布时间：{{ detail.gmt_create }}</div>
      <div class="article-content" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/article.css";

export default {
  data() {
    return {
      code: this.$route.params.code,
      detail: {}
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initDetail();
  },
  methods: {
    /**
     初始化热招岗位
     @param
     @return
     */
    initDetail() {
      this.$axios.post(`${this.$request.live}/common/pc/consultation-detail`, { code: this.code }).then(res => {
        if (res.data.id == 0) {
          this.$router.push({ path: "/about/consultation" });
          return;
        }
        this.detail = res.data;
        // 设置 img
        this.$nextTick(() => {
          const imgs = document.querySelectorAll(".article-content img");
          imgs.forEach(img => {
            let attrWidth = img.attributes.width.value;
            let attrHeight = img.attributes.height.value;
            let displayWidth = img.clientWidth;
            let displayHeight = img.clientHeight;
            if (attrWidth / attrHeight !== displayWidth / displayHeight) {
              // 宽度 用 clientWidth
              let width = displayWidth;
              let height = width * (attrHeight / attrWidth);
              img.setAttribute("style", `width: ${width}px; height: ${height}px;`);
            }
          });
        });
      });
    },

    goBack() {
      this.$router.push({path: '/about/consultation'})
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  position: sticky;
  top: 5.1rem;
  width: 100%;
  height: 3.6rem;
  background: #FAFAFA;
  z-index: 1;
  padding: 0 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333333;
  line-height: 3.6rem;
  cursor: pointer;
}

#article {
  position: relative;
  width: 100%;
  padding: 2.2rem 1.2rem 3rem 1.2rem;

  > .title {
    font-size: 1.6rem;
    font-weight: bolder;
    color: #333333;
    line-height: 2.2rem;
    margin-bottom: 0.8rem;
  }

  > .date {
    font-size: 1.2rem;
    color: #999999;
    line-height: 1.8rem;
    margin-bottom: 2.4rem;
  }

  > .article-content {
    overflow-x: hidden;
  }

}
</style>
