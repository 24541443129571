import { render, staticRenderFns } from "./wrightin.vue?vue&type=template&id=efd7cc76&scoped=true&"
import script from "./wrightin.vue?vue&type=script&lang=js&"
export * from "./wrightin.vue?vue&type=script&lang=js&"
import style0 from "./wrightin.vue?vue&type=style&index=0&id=efd7cc76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd7cc76",
  null
  
)

export default component.exports