<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" v-lazy='require("@/assets/img/about/consultation/banner-bg.png")'>
    </div>
    <div id="consultation-list">
      <div class="consultation-list">
        <div class="consultation-item no-show" v-for="(consultationItem, i) in consultation" :key="i"
             @click="goDetail(consultationItem.code)">
          <div class="img-content"
               :style="{'background-image': 'url('+consultationItem.cover+')'}"></div>
          <div class="title">{{ consultationItem.title }}</div>
          <div class="desc">{{ consultationItem.desc }}</div>
          <div class="date">{{ consultationItem.gmt_create }}</div>
        </div>
      </div>
      <div @click="loadMoreClick" :class="['load-more', isClicked ? 'clicked' : '']" v-if="this.showLoadMore">
        <span>加载更多</span>
        <img alt="" src="@/assets/img/about/consultation/down.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/animate.min.css";

export default {
  data() {
    return {
      consultation: [],
      pageIndex: 1,
      showLoadMore: false,
      isRequest: false,
      isClicked: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadConsultation();
  },
  methods: {

    loadMoreClick() {
      this.isClicked = true;
      this.loadConsultation();
    },
    /**
     @param
     @return
     */
    loadConsultation(isInit) {
      if (this.isRequest) {
        return;
      }
      this.isRequest = true;
      this.$axios.post(`${this.$request.live}/common/pc/consultation-list`, { page_index: this.pageIndex }).then(res => {
        this.isClicked = false;
        this.consultation = this.consultation.concat(res.data.list);
        this.isRequest = false;
        this.pageIndex++;
        if (res.data.is_last_page == "1") {
          this.showLoadMore = false;
        } else {
          this.showLoadMore = true;
        }
      });
    },

    goDetail(code) {
      this.$router.push({ path: "/about/consultation/detail/" + code });
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 18rem;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

#consultation-list {
  width: 100%;
  padding: 2.4rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .consultation-list {
    width: 35rem;

    > .consultation-item {
      animation: fadeInUp 0.5s;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      background-color: #FAFAFA;
      margin-bottom: 1.6rem;

      > .img-content {
        width: 35rem;
        height: 19.7rem;
        background-position: center;
        background-size: cover;
      }

      > .title {
        margin-top: 1.3rem;
        padding: 0 1.2rem;
        font-size: 1.6rem;
        font-weight: bold;
        color: #333333;
        line-height: 2.2rem;
      }

      > .desc {
        margin-top: 0.8rem;
        padding: 0 1.2rem;
        font-size: 1.3rem;
        color: #666666;
        line-height: 2.2rem;
        height: 6.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      > .date {
        margin-top: 0.8rem;
        margin-bottom: 1.2rem;
        padding: 0 1.2rem;
        font-size: 1.3rem;
        color: #999999;
        line-height: 2.2rem;
      }

    }
  }

  > .load-more {
    width: 10.4rem;
    height: 3.2rem;
    border-radius: 2.15rem;
    border: 0.1rem solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.4rem 0 0 0;
    cursor: pointer;

    > span {
      font-size: 1.2rem;
      color: #333333;
      margin-right: 0.4rem;
    }

    > img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  > .load-more.clicked {
    animation: pulse 0.5s;
  }
}
</style>
