<template>
  <div id="main">
    <div id="banner">
      <swiper ref="swiper" :datas="banner"></swiper>
    </div>
    <div id="product">
      <div class="product-item wrightin-t20">
        <img class="img" v-lazy='require("@/assets/img/home/product-t20.png")'>
        <p class="title">精准治疗 紧致升级</p>
        <p class="subTitle">创新传统盆底修复</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">澜渟T20·新一代 <br/>盆底肌修复仪</p>
        <p class="desc">双重修复 紧致系统</p>
        <button class="btn" @click='goPage("/product/t20")'>了解更多</button>
      </div>
      <div class="product-item wrightin-luolan">
        <img class="img" v-lazy='require("@/assets/img/home/product-wrightinLuolan.png")'>
        <p class="title">三维度医养护</p>
        <p class="subTitle">呵护产后妈妈的蜕变</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">洛澜盆底康复治疗仪</p>
        <p class="desc">多维修复 医疗级诊疗方案</p>
        <button class="btn" @click='goPage("/product/luolan")'>了解更多</button>
      </div>
      <div class="product-item wrightin-mulan">
        <img class="img" v-lazy='require("@/assets/img/home/product-wrightinMulan.png")'>
        <p class="title">化繁为简 一机双用</p>
        <p class="subTitle">强韧盆底 重塑腹肌曲</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">沐澜盆底康复仪</p>
        <p class="desc">盆底肌+腹直肌 一机双修复</p>
        <button class="btn" @click='goPage("/product/mulan")'>了解更多</button>
      </div>
      <div class="product-item wrightin-two">
        <img class="img" v-lazy='require("@/assets/img/home/product-wrightinTwo.png")'>
        <p class="title">盆底抗衰神器</p>
        <p class="subTitle">让宝妈重拾少女紧致</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">T20 Max 盆底肌修复仪</p>
        <p class="desc">足不出户唤醒盆底肌肉</p>
        <button class="btn" @click='goPage("/product/wrightinTwo")'>了解更多</button>
      </div>
      <div class="product-item wrightin">
        <img class="img" v-lazy='require("@/assets/img/home/product-wrightin.png")'>
        <p class="title">盆底黑科技</p>
        <p class="subTitle">只属于你的贴身盆底康复师</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">初澜盆底生物刺激反馈仪</p>
        <p class="desc">在家治疗盆底肌肉功能障碍</p>
        <button class="btn" @click='goPage("/product/wrightin")'>了解更多</button>
      </div>
      <div class="product-item kegel">
        <img class="img" v-lazy='require("@/assets/img/home/product-kegel.png")'>
        <p class="title">5球组合出击</p>
        <p class="subTitle">感受紧致魅力</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">盆底肌肉康复器</p>
        <p class="desc">凯格尔球</p>
        <button class="btn" @click='goPage("/product/kegel")'>了解更多</button>
      </div>
      <div class="product-item wireless">
        <img class="img" v-lazy='require("@/assets/img/home/product-wireless.png")'>
        <p class="title">健康塑形新主张</p>
        <p class="subTitle">让妈妈大胆炫腹</p>
        <div class="vi-symbol symbol"></div>
        <p class="product-name">无线产后恢复仪</p>
        <p class="desc">躺着告别大腹婆</p>
        <button class="btn" @click='goPage("/product/wireless")'>了解更多</button>
      </div>
    </div>
    <div id="app">
      <div class="app-detail user">
        <p class="title">澜渟用户版</p>
        <p class="text">凯格尔训练指导</p>
        <img class="img" v-lazy='require("@/assets/img/home/app-user.png")'>
      </div>
      <div class="app-detail doctor">
        <p class="title">澜渟医生版</p>
        <p class="text">医生进阶学习</p>
        <img class="img" v-lazy='require("@/assets/img/home/app-doctor.png")'>
      </div>
      <p class="app-title">澜渟智能服务平台</p>
      <p class="app-subTitle">家用盆底康复软件</p>
      <div class="vi-symbol app-symbol"></div>
      <p class="app-descTitle">两大盆底App</p>
      <p class="app-desc">助力盆底康复行业智慧化升级</p>
      <button class="app-btn" @click='goPage("/app")'>了解更多</button>
    </div>
    <div id="server">
      <img class="server-bg" v-lazy='require("@/assets/img/home/server-bg.png")'>
      <p class="server-title">家庭盆底解决方案服务商</p>
      <div class="server-content">
        <div class="server-item">
          <strong>34<em>个</em></strong>
          <span style="text-align: center;">省、自治区和<br />直辖市市场</span>
        </div>
        <div class="server-item">
          <strong>10<em>年+</em></strong>
          <span>盆底领域经验</span>
        </div>
        <div class="server-item">
          <strong>150<em>W+</em></strong>
          <span>APP终端用户</span>
        </div>
        <div class="server-item">
          <strong>10000<em>+</em></strong>
          <span>全国医疗产康机构</span>
        </div>
        <div class="server-item">
          <strong>100<em>+</em></strong>
          <span>知识产权</span>
        </div>
      </div>
    </div>
    <div id="advantage">
      <img class="advantage-bg" v-lazy='require("@/assets/img/home/advantage-bg.png")'>
      <p class="advantage-title">公司优势</p>
      <p class="advantage-item quality">
        <img class="img" v-lazy='require("@/assets/img/home/advantage-quality.png")'>
        <span class="title">品质</span>
        <span class="desc">追求一流的产品和服务，对品质把关严苛，经过100万+用户实际考验，品质不凡</span>
      </p>
      <p class="advantage-item technology">
        <img class="img" v-lazy='require("@/assets/img/home/advantage-technology.png")'>
        <span class="title">技术</span>
        <span class="desc">经验丰富的技术研发团队，强大的自主研发实力，持有多项专利</span>
      </p>
      <p class="advantage-item service">
        <img class="img" v-lazy='require("@/assets/img/home/advantage-service.png")'>
        <span class="title">服务</span>
        <span class="desc">拥有一支盆底领域人才团队，造就优质营销培训体系和服务体系</span>
      </p>
      <p class="advantage-item trust">
        <img class="img" v-lazy='require("@/assets/img/home/advantage-trust.png")'>
        <span class="title">信誉</span>
        <span class="desc">深耕女性盆底市场多年，品牌认知度高，客户信任度高</span>
      </p>
    </div>
    <div id="bottom"></div>
    <div id="consultation" v-if="!consultationLoading">
      <div class="consultation-top">
        <div class="consultation-title">品牌资讯</div>
        <div class="consultation-list">
          <div class="consultation-item" v-for="(consultationItem, i) in consultation" :key="i"
               @click="goDetail(consultationItem.code)">
            <div class="consultation-item-img"
                 :style="{'background-image': 'url('+consultationItem.cover+')'}"></div>
            <div class="consultation-item-title">{{ consultationItem.title }}</div>
            <div class="consultation-item-time">{{ consultationItem.gmt_create }}</div>
          </div>
        </div>
      </div>
      <div class="consultation-more">
        <div @click="goConsultation" :class="['more-btn', isClicked ? 'clicked' : '']">
          <span>了解更多</span>
          <img alt="" src="@/assets/img/about/consultation/down.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/animate.min.css";
import { swiper } from "@/components/swiper";

export default {
  data() {
    return {
      banner: [
        {
          path: require("@/assets/img/home/banner-t20.png"),
          event: () => {
            this.goPage("/product/t20");
          }
        },
        { path: require("@/assets/img/home/banner-0.png") },
        { path: require("@/assets/img/home/banner-1.png") },
        { path: require("@/assets/img/home/banner-2.png") },
        { path: require("@/assets/img/home/banner-3.png") },
        { path: require("@/assets/img/home/banner-4.png") }
      ],
      consultation: [],
      consultationLoading: true,
      isClicked: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$axios.post(`${this.$request.live}/common/pc/consultation-list`, { page_index: 1, page_size: 4 }).then(res => {
      this.consultation = res.data.list;
      this.consultationLoading = false;
    });
  },
  methods: {
    /**
     页面跳转
     @param {String} path 页面路径
     @return
     */
    goPage(path) {
      this.$router.push({ path: path });
    },

    goDetail(code) {
      this.$router.push({ path: "/about/consultation/detail/" + code });
    },

    goConsultation() {
      this.isClicked = true;
      this.$router.push({ path: "/about/consultation" });
    }
  },
  components: {
    swiper
  }
};
</script>

<style lang="scss" scoped>
#main {
  background-color: #fff;
}

#banner {
  height: 18rem;
}

#product {

  > .product-item {
    position: relative;
    height: 25rem;
    padding-top: 0.4rem;
    background-color: #ffffff;

    > .img {
      position: absolute;
    }

    > .title {
      position: absolute;
      line-height: 2.4rem;
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
      word-break: keep-all;
    }

    > .subTitle {
      position: absolute;
      line-height: 2.4rem;
      font-size: 1.4rem;
      text-align: center;
      word-break: keep-all;
    }

    > .symbol {
      position: absolute;
      width: 2rem;
      height: 0.409rem;
    }

    > .product-name {
      position: absolute;
      font-size: 1.4rem;
      font-weight: bold;
      color: $md-color-primary;
      line-height: 2rem;
      text-align: center;
      word-break: keep-all;
    }

    > .desc {
      position: absolute;
      font-size: 1.2rem;
      color: #666666;
      line-height: 1.7rem;
      text-align: center;
      word-break: keep-all;
    }

    > .btn {
      position: absolute;
      width: 10rem;
      height: 3.2rem;
      text-align: center;
      border-radius: 2.15rem;
      background: linear-gradient(to left, $md-color-secondary, $md-color-primary);
      font-size: 1.5rem;
      color: #FFFFFF;
      line-height: 2.1rem;
    }
  }

  > .product-item.wrightin-t20 {
    background: #FAFBFF;
    padding-top: 0;

    > .img {
      top: 5.4rem;
      right: 1.15rem;
      width: 19.65rem;
    }

    > .title {
      top: 4rem;
      left: 2rem;
    }

    > .subTitle {
      top: 6.5rem;
      left: 4rem;
    }

    > .symbol {
      top: 9.4rem;
      left: 8.75rem;
    }

    > .product-name {
      top: 10.95rem;
      left: 4.45rem;
    }

    > .desc {
      top: 15.3rem;
      left: 4.6rem;
    }

    > .btn {
      top: 18.2rem;
      left: 4.75rem;
    }
  }

  > .product-item.wrightin-luolan {

    > .img {
      top: 4.5rem;
      left: 2rem;
      width: 16.8rem;
      height: 16rem;
    }

    > .title {
      top: 4.7rem;
      left: 21.3rem;
      right: 3.6rem;
    }

    > .subTitle {
      bottom: 15.5rem;
      left: 21.3rem;
      right: 3.6rem;
    }

    > .symbol {
      top: 10.1rem;
      left: 26.6rem;
    }

    > .product-name {
      top: 11.6rem;
      left: 21.3rem;
      right: 3.6rem;
    }

    > .desc {
      top: 14rem;
      left: 20.8rem;
      right: 2.8rem;
    }

    > .btn {
      top: 17.1rem;
      left: 22.6rem;
    }
  }

  > .product-item.wrightin-mulan {
    background: #FAFBFF;

    > .img {
      top: 4.5rem;
      right: 1.6rem;
      width: 16.8rem;
      height: 16rem;
    }

    > .title {
      top: 4.7rem;
      left: 3rem;
    }

    > .subTitle {
      bottom: 15.5rem;
      left: 3rem;
      right: 19.5rem;
    }

    > .symbol {
      top: 10.1rem;
      left: 9.5rem;
    }

    > .product-name {
      top: 11.6rem;
      left: 5.6rem;
      right: 22.1rem;
    }

    > .desc {
      top: 14rem;
      left: 3.3rem;
      right: 19.5rem;
    }

    > .btn {
      top: 17.1rem;
      left: 5.5rem;
    }
  }

  > .product-item.wrightin-two {
    > .img {
      top: 8.4rem;
      left: 1.2rem;
      width: 19.8rem;
      height: 12.8rem;
    }

    > .title {
      top: 4.5rem;
      left: 21.2rem;
      right: 3.7rem;
    }

    > .subTitle {
      bottom: 15.7rem;
      left: 21.2rem;
      right: 3.7rem;
    }

    > .symbol {
      top: 10.1rem;
      left: 26.5rem;
    }

    > .product-name {
      top: 11.8rem;
      left: 19.9rem;
      right: 2.4rem;
    }

    > .desc {
      top: 14.2rem;
      left: 21.5rem;
      right: 4rem;
    }

    > .btn {
      top: 17.3rem;
      left: 22.6rem;
    }
  }

  > .product-item.wrightin {
    background: #FAFBFF;

    > .img {
      top: 4.5rem;
      right: 1.2rem;
      width: 16.8rem;
      height: 16rem;
    }

    > .title {
      top: 4.7rem;
      left: 2rem;
      right: 18.7rem;
    }

    > .subTitle {
      bottom: 15.8rem;
      left: 2rem;
      right: 18.7rem;
    }

    > .symbol {
      top: 10rem;
      left: 9.4rem;
    }

    > .product-name {
      top: 11.7rem;
      left: 2.7rem;
      right: 19.4rem;
    }

    > .desc {
      top: 14.1rem;
      left: 3.2rem;
      right: 19.9rem;
    }

    > .btn {
      top: 17.2rem;
      left: 5.4rem;
    }
  }

  > .product-item.kegel {
    > .img {
      top: 4.1rem;
      left: 2rem;
      width: 16.8rem;
      height: 16.8rem;
    }

    > .title {
      top: 4.7rem;
      left: 22.2rem;
      right: 4.6rem;
    }

    > .subTitle {
      bottom: 15.8rem;
      left: 22.2rem;
      right: 4.6rem;
    }

    > .symbol {
      top: 10rem;
      left: 26.6rem;
    }

    > .product-name {
      top: 11.7rem;
      left: 22.7rem;
      right: 5rem;
    }

    > .desc {
      top: 14.1rem;
      left: 25.2rem;
      right: 7.5rem;
    }

    > .btn {
      top: 17.2rem;
      left: 22.6rem;
    }
  }

  > .product-item.wireless {
    background: #FAFBFF;

    > .img {
      top: 4rem;
      right: 2rem;
      width: 17.8rem;
      height: 17rem;
    }

    > .title {
      top: 4.5rem;
      left: 3.7rem;
      right: 21.2rem;
    }

    > .subTitle {
      bottom: 15.7rem;
      left: 3.7rem;
      right: 21.2rem;
    }

    > .symbol {
      top: 10.1rem;
      left: 9rem;
    }

    > .product-name {
      top: 11.8rem;
      left: 5.1rem;
      right: 22.6rem;
    }

    > .desc {
      top: 14.2rem;
      left: 5.8rem;
      right: 23.3rem;
    }

    > .btn {
      top: 17.3rem;
      left: 4.9rem;
    }
  }
}

#app {
  position: relative;
  padding-top: 0.4rem;
  height: 45rem;
  background-color: #ffffff;

  > .app-detail {
    position: absolute;
    top: 3.1rem;
    width: 8.5rem;
    height: 21.15rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .title {
      position: absolute;
      left: 0;
      right: 0;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: center;
    }

    > .text {
      position: absolute;
      left: 0;
      right: 0;
      top: 2.1rem;
      font-size: 1rem;
      color: #666666;
      line-height: 1.4rem;
      text-align: center;
    }

    > .img {
      position: absolute;
      top: 4.9rem;
      width: 8.1rem;
      height: 16.2rem;
    }
  }

  > .app-detail.user {
    left: 8.7rem;
  }

  > .app-detail.doctor {
    right: 8.4rem;
  }

  > .app-title {
    position: absolute;
    top: 26.7rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 2.5rem;
    font-size: 1.8rem;
    font-weight: bold;
  }

  > .app-subTitle {
    position: absolute;
    bottom: 13.5rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 2.5rem;
    font-size: 1.4rem;
  }

  > .app-symbol {
    position: absolute;
    top: 32rem;
    left: 17.8rem;
    width: 2.5rem;
    height: 0.511rem;
  }

  > .app-descTitle {
    position: absolute;
    top: 33.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 600;
    color: $md-color-primary;
    line-height: 2rem;
  }

  > .app-desc {
    position: absolute;
    top: 35.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: #666666;
    line-height: 1.7rem;
  }

  > .app-btn {
    position: absolute;
    top: 38.8rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 10rem;
    height: 3.2rem;
    text-align: center;
    border-radius: 2.15rem;
    background: linear-gradient(to left, $md-color-secondary, $md-color-primary);
    font-size: 1.5rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2.1rem;
  }
}

#server {
  position: relative;
  margin-top: 0.4rem;
  height: 26.1rem;

  > .server-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  > .server-title {
    position: absolute;
    z-index: 2;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 2.5rem;
    font-weight: bold;
    font-size: 1.8rem;
    color: #fff;
  }

  > .server-content {
    position: absolute;
    top: 8.9rem;
    bottom: 3.6rem;
    left: 1.2rem;
    right: 1.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 2;
    align-content: space-around;

    > .server-item {
      width: 33.333333%;
      display: flex;
      flex-direction: column;
      align-items: center;

      > strong {
        font-weight: bolder;
        font-size: 1.8rem;
        color: white;
        display: flex;
        align-items: center;

        > em {
          font-style: normal;
          font-size: 1.2rem;
          color: white;
          margin-left: .2rem;
        }
      }

      > span {
        margin-top: .1rem;
        line-height: 1.4rem;
        font-size: 1rem;
        color: #fff;
        white-space: nowrap;
      }
    }
  }
}

#advantage {
  position: relative;
  margin-top: 0.3rem;
  height: 42.6rem;

  > .advantage-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  > .advantage-title {
    position: absolute;
    z-index: 2;
    top: 2.4rem;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 2.5rem;
    font-size: 1.8rem;
    font-weight: bold;
  }

  > .advantage-item {
    position: absolute;
    z-index: 2;
    width: 16.9rem;
    height: 17.2rem;
    border-radius: 0.4rem;
    box-shadow: 0 0.2rem 1.2rem 0 #ececec;
    background-color: #fff;

    > .img {
      position: absolute;
      top: 1.7rem;
      left: 0.8rem;
      width: 3.2rem;
      height: 3.2rem;
    }

    > .title {
      position: absolute;
      top: 2rem;
      left: 4.1rem;
      line-height: 2.5rem;
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: 0.075rem;
    }

    > .desc {
      position: absolute;
      top: 5.5rem;
      left: 1.2rem;
      right: 1.2rem;
      line-height: 2.4rem;
      font-size: 1.4rem;
      color: #666;
    }
  }

  > .advantage-item.quality {
    top: 6.5rem;
    left: 1.2rem;
  }

  > .advantage-item.technology {
    top: 6.5rem;
    right: 1.2rem;
  }

  > .advantage-item.service {
    top: 24.9rem;
    left: 1.2rem;
  }

  > .advantage-item.trust {
    top: 24.9rem;
    right: 1.2rem;
  }
}

#bottom {
  height: 2.4rem;
  background-color: #fafafa;
}

#consultation {
  animation: fadeInUp 0.5s;
  position: relative;
  height: 55rem;
  background: #fff;

  > .consultation-top {
    position: relative;
    height: 45.8rem;
    background: url(../assets/img/home/consultation-bg.png) no-repeat top;
    background-size: 100%;
    padding: 2.4rem 1.2rem 0 1.2rem;

    > .consultation-title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 2.5rem;
      width: 100%;
      text-align: center;
    }

    > .consultation-list {
      margin-top: 2.4rem;
      display: flex;
      height: 38.5rem;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;

      > .consultation-item {
        position: relative;
        width: 16.7rem;
        height: 18.4rem;
        background: #fafafa;
        cursor: pointer;

        > .consultation-item-img {
          width: 16.7rem;
          height: 9.4rem;
          background-position: center;
          background-size: cover;
        }

        > .consultation-item-title {
          width: 100%;
          padding: 0 0.8rem;
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 2rem;
          margin-top: 1.4rem;
          height: 4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        > .consultation-item-time {
          width: 100%;
          padding: 0 0.8rem;
          font-size: 1rem;
          color: #999999;
          line-height: 1.6rem;
          bottom: 1.2rem;
          position: absolute;
        }
      }
    }
  }

  > .consultation-more {
    height: 9.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > .more-btn {
      width: 10.4rem;
      height: 3.2rem;
      border-radius: 2.15rem;
      border: 0.1rem solid #999999;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      > span {
        font-size: 1.2rem;
        color: #333333;
        margin-right: 0.4rem;
      }

      > img {
        width: 1.6rem;
        height: 1.6rem;
        rotate: 270deg;
      }
    }

    > .more-btn.clicked {
      animation: pulse 0.5s;
    }
  }
}
</style>
