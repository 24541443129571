<template>
  <div id="main">
    <div id="banner">
    </div>
    <div id="apply" class="content-item">
      <p class="apply-title">沐澜盆底康复仪</p>
      <p class="apply-subTitle">修复腹直肌 治疗盆底功能障碍</p>
      <div class="vi-symbol apply-symbol"></div>
      <p class="apply-content">/ 适用于 /</p>
      <div class="apply-for">
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_1.png")' />
          <span class="for-item-text">治疗压力<br />性尿失禁</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_2.png")' />
          <span class="for-item-text">治疗盆腔<br />器官脱垂</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_3.png")' />
          <span class="for-item-text">缓解慢性<br />盆腔疼痛</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_4.png")' />
          <span class="for-item-text">产后盆底<br />功能康复</span>
        </div>
      </div>
      <div class="apply-for" style="top: 26.5rem">
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_5.png")' />
          <span class="for-item-text">治疗腹直<br />肌分离</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_6.png")' />
          <span class="for-item-text">治疗阴道<br />松弛症</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_7.png")' />
          <span class="for-item-text">增强阴道<br />收缩功能</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/mulan/disease_8.png")' />
          <span class="for-item-text">治疗女性<br />性功能障碍</span>
        </div>
      </div>
    </div>
    <div id="technology" class="content-item">
      <span class="technology-title">生电联合</span>
      <span class="technology-subTitle">双重技术赋能产后康复</span>
      <div class="vi-symbol technology-symbol"></div>
      <img class="technology-img" :src='require("@/assets/img/product/mulan/technology_img.png")' />
    </div>
    <div id="dimension" class="content-item">
      <span class="dimension-title">化繁为简 一机双用</span>
      <span class="dimension-subTitle">强韧盆底  重塑腹肌曲线</span>
      <div class="vi-symbol dimension-symbol"></div>
      <img class="dimension-img" :src='require("@/assets/img/product/mulan/dimension_img.png")' />
      <img class="dimension-img-append" :src='require("@/assets/img/product/mulan/dimension_img_append.png")' />
    </div>
    <div id="model" class="content-item">
      <span class="model-title">一重·盆底治疗</span>
      <span class="model-subTitle">稳固盆底肌力 悦享自在生活</span>
      <div class="vi-symbol model-symbol"></div>
      <p class="model-content">CACT盆底康复模型，循序渐进，科学修复盆底</p>
      <img class="model-img" :src='require("@/assets/img/product/mulan/model_img.png")' />
      <img class="model-img-append" :src='require("@/assets/img/product/mulan/model_img_append.png")' />
    </div>
    <div id="problem" class="content-item">
      <span class="problem-title">盆底问题 多维评估</span>
      <div class="vi-symbol problem-symbol"></div>
      <p class="problem-content">直观反馈盆底综合情况，智能生成数字化评估报告</p>
      <img class="problem-img" :src='require("@/assets/img/product/mulan/problem_img.png")' />
    </div>
    <div id="assess" class="content-item">
      <span class="assess-title">智能定制方案 千人千面</span>
      <div class="vi-symbol assess-symbol"></div>
      <p class="assess-content">AI大数据智能算法，根据个人情况，结合评估结果<br />针对性解决多种问题，灵活满足个性化治疗需求</p>
      <img class="assess-img" :src='require("@/assets/img/product/mulan/assess_img.png")' />
    </div>
    <div id="pattern" class="content-item">
      <span class="pattern-title">三大训练模式 科学治疗</span>
      <div class="vi-symbol pattern-symbol"></div>
      <p class="pattern-content">“主被动结合”的科学训练方案，循序渐进，稳步修复</p>
      <img class="pattern-img" :src='require("@/assets/img/product/mulan/pattern_img.png")' />
      <div class="pattern-row">
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/mulan/pattern_1.png")' />
          <p class="pattern-item-text">神经肌肉<br />电刺激</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/mulan/pattern_5.png")' />
          <p class="pattern-item-text">牵张模板<br />训练</p>
        </div>
        <div class="pattern-item">
          <img class="pattern-item-img" :src='require("@/assets/img/product/mulan/pattern_6.png")' />
          <p class="pattern-item-text">牵张放松<br />训练</p>
        </div>
      </div>
    </div>
    <div id="ck" class="content-item">
      <span class="ck-title">二重·腹直肌分离治疗</span>
      <span class="ck-subTitle">修复腹直肌 塑造曲线美学</span>
      <div class="vi-symbol ck-symbol"></div>
      <p class="ck-content">EMS电刺激，仿人手按摩，深度舒缓肌肉疲劳<br />帮助紧致塑形，促进神经兴奋、局部血液循环</p>
      <img class="ck-img" :src='require("@/assets/img/product/mulan/ck_img.png")' />
      <img class="ck-img-append" :src='require("@/assets/img/product/mulan/ck_img_append.png")' />
    </div>
    <div id="point" class="content-item">
      <span class="point-title">多点选择 全身享受</span>
      <div class="vi-symbol point-symbol"></div>
      <p class="point-content">作用身体多部位，由内而外，全面修护</p>
      <img class="point-img" :src='require("@/assets/img/product/mulan/point_img.png")' />
      <div class="point-one">
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/mulan/point_1.png")' />
          <span class="point-item-text">腹部</span>
        </div>
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/mulan/point_4.png")' />
          <span class="point-item-text">肩颈</span>
        </div>
      </div>
      <div class="point-two">
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/mulan/point_5.png")' />
          <span class="point-item-text">手臂</span>
        </div>
        <div class="point-item">
          <img class="point-item-img" :src='require("@/assets/img/product/mulan/point_7.png")' />
          <span class="point-item-text">腿部</span>
        </div>
      </div>
      <span class="point-etc">......</span>
    </div>
    <div id="expr" class="content-item">
      <span class="expr-title">双向对称方波</span>
      <div class="vi-symbol expr-symbol"></div>
      <span class="expr-content">电刺激输出均衡柔和，提升治疗舒适度</span>
      <img class="expr-img" :src='require("@/assets/img/product/mulan/expr_img.png")' />
    </div>
    <div id="care" class="content-item">
      <span class="care-title">智能关怀 贴心守护</span>
      <div class="vi-symbol care-symbol"></div>
      <img class="care-img" :src='require("@/assets/img/product/mulan/care_img.png")' />
    </div>
    <div id="hommization" class="content-item">
      <span class="hommization-title">人性化设计 匠心专研</span>
      <div class="vi-symbol hommization-symbol"></div>
      <div class="hommization-item" style="width: 13.4rem; top: 9.6rem; left: 4.1rem;">
        <p class="hommization-item-title">圆润鹅卵石外观</p>
        <p class="hommization-item-text">精致小巧彰显高贵</p>
      </div>
      <div class="hommization-item" style="width: 13.4rem; top: 9.6rem; right: 4.2rem;">
        <p class="hommization-item-title">OTA远程升级</p>
        <p class="hommization-item-text">硬件功能一键迭代</p>
      </div>
      <div class="hommization-item" style="width: 13.4rem; top: 17.4rem; left: 4.1rem;">
        <p class="hommization-item-title">蓝牙5.1</p>
        <p class="hommization-item-text">连接更稳定传输更高效</p>
      </div>
      <div class="hommization-item" style="width: 13.4rem; top: 17.4rem; right: 4.2rem;">
        <p class="hommization-item-title">双通道设计</p>
        <p class="hommization-item-text">评估治疗一体化</p>
      </div>
      <img class="hommization-img" :src='require("@/assets/img/product/mulan/hommization_img.png")' />
    </div>
    <div id="connect" class="content-item">
      <span class="connect-title">一键连接 智能训练</span>
      <div class="vi-symbol connect-symbol"></div>
      <span class="connect-content">产品通过蓝牙连接配套App<br />享受简单愉悦的智能训练体验</span>
      <img class="connect-circle" :src='require("@/assets/img/product/mulan/new_circle.gif")' />
      <img class="connect-img-append1" :src='require("@/assets/img/product/mulan/connect_img_append_1.png")' />
      <img class="connect-img-append2" :src='require("@/assets/img/product/mulan/connect_img_append_2.png")' />
      <img class="connect-img" :src='require("@/assets/img/product/mulan/connect_img.png")' />
    </div>
    <div id="auth" class="content-item">
      <span class="auth-title">国家医疗器械认证</span>
      <div class="vi-symbol auth-symbol"></div>
      <span class="auth-content">匠心卓越</span>
      <div class="auth-img">
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/mulan/auth_img_1.png")' />
          <span class="auth-item-title">盆底康复仪</span>
          <span class="auth-item-text">二类医疗器械</span>
        </div>
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/mulan/auth_img_2.png")' />
          <span class="auth-item-title">盆底康复训练软件</span>
          <span class="auth-item-text">二类医疗器械</span>
        </div>
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/mulan/auth_img_3.png")' />
          <span class="auth-item-title">阴道电极</span>
          <span class="auth-item-text">二类医疗器械</span>
        </div>
        <div class="auth-item">
          <img class="auth-item-img" :src='require("@/assets/img/product/mulan/auth_img_4.png")' />
          <span class="auth-item-title">理疗用体表电极</span>
          <span class="auth-item-text">一类医疗器械备案</span>
        </div>
      </div>
    </div>
    <div id="talk" class="content-item">
      <span class="talk-title">全程陪伴式答疑</span>
      <div class="vi-symbol talk-symbol"></div>
      <span class="talk-content">GMAA持证康复师，在线1V1贴心指导</span>
      <img class="talk-circle" :src='require("@/assets/img/product/mulan/talk_circle.png")' />
      <img class="talk-img-append1" :src='require("@/assets/img/product/mulan/talk_img_append_1.png")' />
      <img class="talk-img-append2" :src='require("@/assets/img/product/mulan/talk_img_append_2.png")' />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
$md-color-primary: $md-color-primary-before;
$md-symbol-img-path: $md-symbol-img-path-before;

#banner {
  position: relative;
  height: 18rem;
  background: url(../../assets/img/product/mulan/banner-bg.png) no-repeat center center;
  background-size: 100% 100%;
}

.vi-symbol {
  position: absolute;
  width: 2.5rem;
  height: 0.511rem;
  background-image: url('../../' + $md-symbol-img-path);
}

.content-item {
  position: relative;
  padding-bottom: 0.4rem;
  height: 40rem;
  background: #ffffff;
}

#apply {
  > .apply-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  > .apply-subTitle {
    position: absolute;
    top: 7.2rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  > .apply-symbol {
    top: 6.1rem;
    left: 17.8rem;
  }

  > .apply-content {
    position: absolute;
    top: 9.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  > .apply-for {
    position: absolute;
    top: 15rem;
    left: 3.3rem;
    right: 3.5rem;
    display: flex;
    justify-content: space-between;

    > .for-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .for-item-img {
        width: 5rem;
      }

      > .for-item-text {
        padding-top: 0.6rem;
        text-align: center;
        font-size: 1.2rem;
        color: $md-color-primary;
        line-height: 1.7rem;
      }
    }
  }
}

#technology {
  background: #FAFBFF;
  height: 35rem;

  > .technology-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  > .technology-subTitle {
    position: absolute;
    top: 5.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  > .technology-symbol {
    top: 8.5rem;
    left: 17.8rem;
  }

  > .technology-img {
    position: absolute;
    top: 12.8rem;
    left: 3.9rem;
    width: 29.7rem;
    height: 16.6rem;
  }
}

#dimension {
  height: 44.5rem;

  > .dimension-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  > .dimension-subTitle {
    position: absolute;
    top: 5.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  > .dimension-symbol {
    top: 8.5rem;
    left: 17.8rem;
  }

  > .dimension-img {
    position: absolute;
    top: 10.7rem;
    left: 2.3rem;
    width: 20rem;
    height: 33.8rem;
  }

  > .dimension-img-append {
    position: absolute;
    top: 12.8rem;
    left: 13.5rem;
    width: 19rem;
    height: 18.8rem;
  }
}

#model {
  background: #FAFBFF;
  height: 41.5rem;

  > .model-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .model-subTitle {
    position: absolute;
    top: 5.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  > .model-symbol {
    top: 8.5rem;
    left: 17.3rem;
  }

  > .model-content {
    position: absolute;
    top: 9.4rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.7rem;
  }

  > .model-img {
    position: absolute;
    top: 12.5rem;
    right: 0.9rem;
    width: 26.8rem;
    height: 29rem
  }

  > .model-img-append {
    position: absolute;
    top: 16.7rem;
    left: 3.4rem;
    width: 21rem;
    height: 16.1rem
  }
}

#problem {
  height: 38rem;

  > .problem-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .problem-symbol {
    top: 6.3rem;
    left: 17.9rem;
  }

  > .problem-content {
    position: absolute;
    top: 7.4rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.7rem;
  }

  > .problem-img {
    position: absolute;
    top: 12.9rem;
    left: 4.2rem;
    width: 27.9rem;
    height: 21.5rem
  }
}

#assess {
  background: #FAFBFF;

  > .assess-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .assess-symbol {
    top: 6.3rem;
    left: 17.3rem;
  }

  > .assess-content {
    position: absolute;
    top: 7.4rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.6rem;
  }

  > .assess-img {
    position: absolute;
    top: 14.8rem;
    left: 4.2rem;
    width: 28rem;
    height: 22rem
  }
}

#pattern {
  height: 48rem;

  > .pattern-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .pattern-symbol {
    top: 6.3rem;
    left: 17.3rem;
  }

  > .pattern-content {
    position: absolute;
    top: 7.4rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.7rem;
  }

  > .pattern-img {
    position: absolute;
    top: 12.9rem;
    left: 4.2rem;
    width: 29rem;
    height: 20rem
  }

  > .pattern-row {
    position: absolute;
    width: 21.6rem;
    top: 35.9rem;
    left: 8rem;
    height: 8.3rem;
    display: flex;
    justify-content: space-between;

    > .pattern-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .pattern-item-img {
        width: 4rem;
      }

      > .pattern-item-text {
        padding-top: 0.9rem;
        width: 4.8rem;
        text-align: center;
        font-size: 1.2rem;
        color: $md-color-primary;
        line-height: 1.7rem;
      }
    }
  }

  > .pattern-row.row2 {
    top: 48.5rem;
  }

}

#ck {
  background: #FAFBFF;
  height: 45rem;

  > .ck-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .ck-subTitle {
    position: absolute;
    top: 5.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  > .ck-symbol {
    top: 8.5rem;
    left: 17.9rem;
  }

  > .ck-content {
    position: absolute;
    top: 9.5rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.7rem;
  }

  > .ck-img {
    position: absolute;
    top: 15rem;
    right: 2.8rem;
    width: 25.9rem;
    height: 30rem
  }

  > .ck-img-append {
    position: absolute;
    top: 26.5rem;
    left: 2.8rem;
    width: 16rem;
    height: 16rem
  }
}

#point {
  height: 38rem;

  > .point-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .point-symbol {
    top: 6.3rem;
    left: 17.3rem;
  }

  > .point-content {
    position: absolute;
    top: 7.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.6rem;
  }

  > .point-img {
    position: absolute;
    bottom: 0rem;
    left: 2.7rem;
    width: 30.1rem;
    height: 27.4rem
  }

  > .point-one {
    position: absolute;
    top: 16.3rem;
    left: 23.3rem;
    display: flex;
    justify-content: space-between;
    width: 10rem;

    > .point-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .point-item-img {
        width: 4rem;
      }

      > .point-item-text {
        font-size: 1.2rem;
        color: $md-color-primary;
        line-height: 2.6rem;
      }
    }
  }

  > .point-two {
    position: absolute;
    top: 24.3rem;
    left: 23.3rem;
    display: flex;
    justify-content: space-between;
    width: 10rem;

    > .point-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .point-item-img {
        width: 4rem;
      }

      > .point-item-text {
        font-size: 1.2rem;
        color: $md-color-primary;
        line-height: 2.6rem;
      }
    }
  }

  > .point-etc {
    position: absolute;
    bottom: 3.1rem;
    right: 5rem;
    color: $md-color-primary;
    line-height: 2.6rem;
  }
}

#expr {
  height: 37rem;
  background: #FAFBFF;

  > .expr-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .expr-symbol {
    top: 6.3rem;
    left: 16.8rem;
  }

  > .expr-content {
    position: absolute;
    top: 7.4rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666666;
    line-height: 1.6rem;
  }

  > .expr-img {
    position: absolute;
    top: 12.9rem;
    left: 0;
    width: 37.5rem;
    height: 20rem
  }
}

#care {
  height: 53rem;

  > .care-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .care-symbol {
    top: 6.3rem;
    left: 17.6rem;
  }

  > .care-img {
    position: absolute;
    top: 8.6rem;
    left: 1.6rem;
    width: 34.3rem;
    height: 40.8rem
  }
}

#hommization {
  height: 50rem;
  background: #FAFBFF;

  > .hommization-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .hommization-symbol {
    top: 6.3rem;
    left: 17.5rem;
  }

  > .hommization-img {
    position: absolute;
    top: 22.3rem;
    left: 9.7rem;
    width: 18.1rem;
    height: 23.5rem
  }

  > .hommization-item {
    position: absolute;
    padding: 1rem 0.7rem;
    background: #EEF0FF;
    border-radius: 0.4rem;
    text-align: center;

    > .hommization-item-title {
      font-size: 1.3rem;
      font-weight: bold;
      color: $md-color-primary;
      line-height: 1.6rem;
    }

    > .hommization-item-text {
      margin-top: 0.4rem;
      font-size: 1.2rem;
      color: $md-color-primary;
      line-height: 1.4rem;
    }
  }

}

#connect {
  height: 48rem;

  > .connect-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .connect-symbol {
    top: 6.4rem;
    left: 17.5rem;
  }

  > .connect-content {
    position: absolute;
    top: 7.8rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #666666;
  }

  > .connect-img {
    position: absolute;
    top: 19.9rem;
    left: 22.8rem;
    width: 10.8rem;
    height: 11.1rem;
  }

  > .connect-circle {
    width: 37.5rem;
    position: absolute;
    top: 10.7rem;
    left: -3rem;
  }

  > .connect-img-append1 {
    position: absolute;
    top: 16.1rem;
    left: 4.2rem;
    width: 21.8rem;
    height: 12.6rem;
  }

  > .connect-img-append2 {
    position: absolute;
    top: 29rem;
    left: 14rem;
    width: 20.4rem;
    height: 14.6rem;
  }
}

#auth {
  background: #FAFBFF;
  height: 68rem;

  > .auth-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .auth-symbol {
    top: 6.4rem;
    left: 17.3rem;
  }

  > .auth-content {
    position: absolute;
    top: 7.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #666666;
  }

  > .auth-img {
    position: absolute;
    top: 11.5rem;
    left: 1.8rem;
    width: 33.8rem;
    height: 53.9rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > .auth-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .auth-item-img {
        width: 16.7rem;
        height: 21.2rem;
      }

      > .auth-item-title {
        padding-top: 0.7rem;
        font-size: 1.2rem;
        line-height: 1.8rem;
      }

      > .auth-item-text {
        padding-top: 0.1rem;
        font-size: 1.1rem;
        color: #666666;
        line-height: 1.8rem;
      }
    }
  }
}

#talk {
  height: 38rem;

  > .talk-title {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  > .talk-symbol {
    top: 6.4rem;
    left: 17.3rem;
  }

  > .talk-content {
    position: absolute;
    top: 7.3rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.7rem;
    color: #666666;
  }

  > .talk-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 37.5rem;
    height: 38rem;
  }

  > .talk-img-append1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 37.5rem;
    height: 27.6rem;
  }

  > .talk-img-append2 {
    position: absolute;
    top: 12.5rem;
    left: 3rem;
    width: 20rem;
    height: 12.5rem;
  }
}
</style>
