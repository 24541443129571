<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" :src='require("@/assets/img/about/responsibility/banner.png")'>
    </div>
    <div id="video">
      <div class="video-container">
        <video class="video" ref="myVideo" @click="playVideo" :src="videoPath" muted autoplay
               :poster="coverPath"
               @pause="playState(false)" @play="playState(true)">
        </video>
        <img v-if="!isVideoPlaying" class="playBtn" @click.stop="playVideo"
             :src='require("@/assets/img/product/t20/play_circle.png")' />
      </div>
      <p class="video-title">盆底健康小屋</p>
      <p class="video-desc">从医院到居家无缝连接盆底康复</p>
      <div class="info-list">
        <div class="info-item">
          <img alt="" class="info-icon" :src='require("@/assets/img/about/responsibility/info-1.png")' />
          <div class="info-content">
            <p class="info-title">专业盆底康复设备</p>
            <p class="info-desc">麦澜德医用级生物刺激反馈仪<br />澜渟家用生物刺激反馈仪</p>
          </div>
        </div>
        <div class="info-item">
          <img alt="" class="info-icon" :src='require("@/assets/img/about/responsibility/info-2.png")' />
          <div class="info-content">
            <p class="info-title">医院科室支持</p>
            <p class="info-desc">提供专业的盆底科普知识<br />帮助妇女了解盆底健康的重要性</p>
          </div>
        </div>
        <div class="info-item">
          <img alt="" class="info-icon" :src='require("@/assets/img/about/responsibility/info-3.png")' />
          <div class="info-content">
            <p class="info-title">盆底健康科普</p>
            <p class="info-desc">医院妇幼保健院与品牌共同运营<br />对患者盆底进行检测、评估和治疗</p>
          </div>
        </div>
      </div>
    </div>
    <div id="ip">
      <img class="ip-left" :src='require("@/assets/img/about/responsibility/ip-left.png")'>
      <img class="ip-right" :src='require("@/assets/img/about/responsibility/ip-right.png")'>
    </div>
    <div id="plan">
      <div class="plan-list">
        <div class="plan-item">
          <img class="plan-icon" alt="" :src='require("@/assets/img/about/responsibility/one.png")' />
          <div class="plan-content">
            <div class="plan-title">西部女性盆底健康援助计划</div>
            <div class="plan-location">
              <img :src='require("@/assets/img/about/responsibility/location.png")' />2024 年青海西宁站
            </div>
            <div class="plan-desc">2024 年 8 月 20 日，澜渟与麦澜德携手江苏省妇儿基金会向青海省妇幼保健院、大通县妇幼保健院捐赠超百万元的盆底康复治疗仪，并挂牌盆底健康小屋。</div>
            <div class="plan-btn" @click="goDetail" data-news="NEWS00013">了解更多<img
              :src='require("@/assets/img/about/responsibility/next.png")' /></div>
          </div>
        </div>
        <div class="plan-item">
          <img class="plan-icon" alt="" :src='require("@/assets/img/about/responsibility/two.png")' />
          <div class="plan-content">
            <div class="plan-title">西部女性盆底健康援助计划</div>
            <div class="plan-location">
              <img :src='require("@/assets/img/about/responsibility/location.png")' />2023 年新疆伊利站
            </div>
            <div class="plan-desc">2023 年 9 月 19 日，澜渟与麦澜德携手江苏省妇儿基金会向伊犁哈萨克自治州友谊医院、伊宁县妇幼保健院，捐赠两台麦澜德医用级生物刺激反馈仪、百余台澜渟家用盆底生物刺激反馈仪。
            </div>
            <div class="plan-btn" @click="goDetail" data-news="NEWS00001">了解更多<img
              :src='require("@/assets/img/about/responsibility/next.png")' /></div>
          </div>
        </div>
        <div class="plan-item">
          <img class="plan-icon" alt="" :src='require("@/assets/img/about/responsibility/three.png")' />
          <div class="plan-content">
            <div class="plan-title">一起挺妈妈线上公益行动</div>
            <div class="plan-location">联合天猫健康、阿里健康等社交平台</div>
            <div class="plan-desc">2023 年 9 月，澜渟联合天猫电商平台发起了#一起挺妈妈#线上公益行动，通过社交媒体进行盆底健康知识的科普，并为产后存在盆底功能障碍疾病的女性群体免费提供盆底肌修复仪。
            </div>
            <div class="plan-btn" @click="goDetail" data-news="NEWS00006">了解更多<img
              :src='require("@/assets/img/about/responsibility/next.png")' /></div>
          </div>
        </div>
      </div>
    </div>
    <div id="award">
      <div class="award-title">
        <img :src='require("@/assets/img/about/responsibility/flag.png")' />
        公益践行奖
        <img :src='require("@/assets/img/about/responsibility/flag.png")' />
      </div>
      <img alt="" class="award-bg" :src='require("@/assets/img/about/responsibility/award.png")'>
      <div class="award-desc">
        南京麦豆健康科技有限公司携澜渟品牌联合江苏省妇女儿童福利基金会发起的“我助妇儿康”关爱女性盆底健康援助计划线上+线下公益项目,荣膺第十三届公益节颁发的“年度公益践行奖”。
      </div>
      <div class="award-btn" @click="goDetail" data-news="NEWS00009">了解更多<img
        :src='require("@/assets/img/about/responsibility/next.png")' /></div>
    </div>
    <div id="footer-content">
      <img class="footer-bg" :src='require("@/assets/img/about/responsibility/bottom-banner.png")'>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      coverPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/train_prepare_cover/${process.env.NODE_ENV === "prod" ? "" : "test/"}website_responsibility.jpeg`,
      videoPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/train_prepare_video/${process.env.NODE_ENV === "prod" ? "" : "test/"}website_responsibility.mp4`,
      isVideoPlaying: false,
      isVideoHover: false
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    setInterval(() => {
      this.pageScroll();
    }, 1000);
  },
  methods: {
    pageScroll() {
      // 判断 video是不是被 header sh
      const videoDom = document.querySelector(".video-container");
      const headerDom = document.querySelector("#top");
      if (videoDom && headerDom) {
        // videoDom 在 屏幕中间
        if (videoDom.getBoundingClientRect().top + videoDom.getBoundingClientRect().height / 2 > headerDom.getBoundingClientRect().height &&
          window.innerHeight - videoDom.getBoundingClientRect().top > videoDom.getBoundingClientRect().height / 2) {
        } else {
          this.$refs.myVideo.pause();
          this.isVideoPlaying = false;
        }
      }
    },
    playState(isPlay) {
      this.isVideoPlaying = isPlay;
      if (isPlay) {
        this.$refs.myVideo.setAttribute("controls", "true");
      } else {
        this.$refs.myVideo.removeAttribute("controls");
      }
    },
    playVideo() {
      this.$refs.myVideo.muted = false;
      this.$refs.myVideo.play();
    },
    goDetail(e) {
      this.$router.push({ path: "/about/consultation/detail/" + e.target.dataset.news });
    }
  }
};
</script>

<style lang="scss" scoped>
#banner {
  position: relative;
  height: 18rem;
  background-color: #ffffff;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

#video {
  padding: 1.6rem;
  background-color: #fff;

  > .video-container {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > .video {
      width: 34.3rem;
      border-radius: 1rem;
    }

    > .playBtn {
      position: absolute;
      cursor: pointer;
      width: 5rem;
    }
  }

  > .video-title {
    margin-top: 4rem;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-align: center;
  }

  > .video-desc {
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: center;
  }

  > .info-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .info-item {
      padding: 2.4rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.01rem solid #f6f6f6;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      &:last-child {
        border-bottom: none;
      }

      > .info-icon {
        width: 14.6rem;
        height: 10rem;
      }

      > .info-content {
        width: 18.1rem;

        > .info-title {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 2.2rem;
        }

        > .info-desc {
          margin-top: 0.8rem;
          color: #666;
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }
    }
  }
}

#ip {
  position: relative;
  background: #EFEBFA;
  display: flex;
  flex-direction: column;

  > .ip-left {
    margin: 4rem auto 0;
    width: 20rem;
  }

  > .ip-right {
    margin: 4.6rem auto 2.4rem;
    width: 34.3rem;
  }
}

#plan {
  background-color: #fff;
  padding: 0 1.6rem;

  .plan-list {
    padding: 2.4rem 0;

    > .plan-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 0 2.4rem;
      margin-bottom: 2.4rem;
      background: #FAFBFF;
      border-radius: 1.2rem;

      &:last-child {
        margin-bottom: 0;
      }

      > .plan-icon {
        width: 100%;
      }

      > .plan-content {
        margin-top: 1.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        > .plan-title {
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 2.5rem;
          text-align: center;
        }

        > .plan-location {
          margin-top: 1.2rem;
          height: 2.8rem;
          background: rgba(114, 84, 188, 0.2);
          border-radius: 10rem 10rem 10rem 10rem;
          padding: 0 1.6rem;
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          line-height: 2.8rem;
          width: fit-content;

          > img {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.8rem;
          }
        }

        > .plan-desc {
          margin-top: 1.2rem;
          font-weight: 400;
          font-size: 1.3rem;
          color: #666666;
          line-height: 1.8rem;
          padding: 0 1.6rem;
        }

        > .plan-btn {
          cursor: pointer;
          margin-top: 1.2rem;
          border-radius: 10rem;
          border: 0.01rem solid #333333;
          width: fit-content;
          height: 3.4rem;
          padding: 0 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.6rem;
          line-height: 3.4rem;

          &:hover, &:active {
            border-color: $md-color-primary;
            color: $md-color-primary;

            > img {
              filter: none;
            }
          }

          > img {
            width: 2rem;
            height: 2rem;
            margin-left: 0.8rem;
            filter: grayscale(1) contrast(10);
          }
        }
      }
    }
  }
}

#award {
  padding: 2.4rem 1.6rem;
  background-color: #F5F6F9;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .award-title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.5rem;
    display: flex;
    align-items: center;

    > img {
      width: 2rem;
      height: 2rem;

      &:last-child {
        transform: scaleX(-1);
      }
    }
  }

  > .award-bg {
    margin-top: 1.6rem;
    width: 100%;
  }


  > .award-desc {
    margin-top: 1.6rem;
    font-weight: 400;
    font-size: 1.3rem;
    color: #666666;
    line-height: 1.8rem;
  }

  > .award-btn {
    cursor: pointer;
    margin-top: 1.6rem;
    border-radius: 10rem;
    border: 0.01rem solid #333333;
    width: fit-content;
    height: 3.4rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    line-height: 3.4rem;

    &:hover, &:active {
      border-color: $md-color-primary;
      color: $md-color-primary;

      > img {
        filter: none;
      }
    }

    > img {
      width: 2rem;
      height: 2rem;
      margin-left: 0.8rem;
      filter: grayscale(1) contrast(10);
    }
  }
}

#footer-content {
  position: relative;
  width: 100%;
  display: flex;

  > .footer-bg {
    width: 100%;
  }
}
</style>
