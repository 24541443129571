<template>
  <div id="main">
    <div id="banner">
      <img class="banner-bg" :src='require("@/assets/img/empower/banner-bg.png")'>
    </div>
    <div id="empower">
      <div class="empower-container">
        <div class="empower-content">
          <div class="empower-title">
            <img class="empower-title-img" :src='require("@/assets/img/empower/logo.png")'/>澜渟品牌授权查询
          </div>
          <form class="empower-search" onsubmit="return false;">
            <input required maxlength="32" v-model:value="inputCode" placeholder="请输入官方授权号" />
            <img @click="clearInput" class="clear-input" v-if="inputCode.length != 0"
                 src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yNCA0NEMzNS4wNDU3IDQ0IDQ0IDM1LjA0NTcgNDQgMjRDNDQgMTIuOTU0MyAzNS4wNDU3IDQgMjQgNEMxMi45NTQzIDQgNCAxMi45NTQzIDQgMjRDNCAzNS4wNDU3IDEyLjk1NDMgNDQgMjQgNDRaIiBmaWxsPSIjYWRhZGFkIiBzdHJva2U9IiNhZGFkYWQiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVqb2luPSJiZXZlbCIvPjxwYXRoIGQ9Ik0yOS42NTY3IDE4LjM0MzJMMTguMzQzIDI5LjY1NjkiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0iYmV2ZWwiLz48cGF0aCBkPSJNMTguMzQzMyAxOC4zNDMyTDI5LjY1NyAyOS42NTY5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49ImJldmVsIi8+PC9zdmc+" />
            <button type="submit" class="empower-search-btn" @click="empowerSearch">
              <img :class="[loading?'spin':'']" :src="searchIcon" />
              查询
            </button>
          </form>
        </div>

        <div v-if="resultShow" class="empower-result">
          <div class="result-title">授权查询结果</div>
          <div v-if="!resultShowSuccess" class="result-content-error">
            您所查询的官方授权号<span>({{ resultCode }})</span>不存在!
          </div>
          <div v-if="resultShowSuccess" class="result-content-success">
            <div>您所查询的官方授权号(<span>{{ resultCode }}</span>)已授权！具体如下：</div>
            <img :src="empowerImage" alt="" />
          </div>
        </div>
      </div>
    </div>

    <md-custom :show.sync="loading" id="load" type="transparent">
    </md-custom>
  </div>
</template>

<script>
import MdCustom from "../components/custom/src/custom";

export default {
  data() {
    return {
      inputCode: "",
      resultShow: false,
      resultShowSuccess: false,
      empowerImage: "",
      resultCode: "",
      loading: false,
      defaultSearchIcon: require("@/assets/img/empower/search_icon.png"),
      searchIcon: require("@/assets/img/empower/search_icon.png")
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    document.getElementById("content").classList.add("empower");
  },
  destroyed() {
    document.getElementById("content").classList.remove("empower");
  },
  methods: {
    empowerSearch() {
      this.resultShow = false;
      this.resultShowSuccess = false;
      if (this.inputCode === "") {
        return;
      }
      this.searchIcon = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik00IDI0QzQgMzUuMDQ1NyAxMi45NTQzIDQ0IDI0IDQ0QzM1LjA0NTcgNDQgNDQgMzUuMDQ1NyA0NCAyNEM0NCAxMi45NTQzIDM1LjA0NTcgNCAyNCA0IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49ImJldmVsIi8+PC9zdmc+";
      this.loading = true;
      this.$axios.post(`${this.$request.live}/common/pc/empower`, {
        code: this.inputCode
      }).then(res => {
        document.getElementById("empower").scrollIntoView({
          block: "start",
          behavior: "smooth"
        });
        this.searchIcon = this.defaultSearchIcon;
        this.loading = false;
        this.resultCode = this.inputCode;
        this.resultShow = true;
        this.resultShowSuccess = res.data.is_exist == 1;
        this.empowerImage = res.data.empower_img;
      });
    },
    clearInput() {
      this.inputCode = "";
      this.resultShow = false;
      this.resultShowSuccess = false;
    }
  },
  components: { MdCustom }
};
</script>
<style>
#content.empower {
  background: #F6F6F6 !important;
}
</style>
<style lang="scss" scoped>
#banner {
  position: relative;
  height: 25rem;

  > .banner-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

#empower {
  padding-top: 7.1rem;
  margin-top: -18.5rem;
  padding-bottom: 5rem;
  background: #F6F6F6;

  > .empower-container {
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > .empower-content {
      width: 32.7rem;
      height: 20.2rem;
      border-radius: 1.6rem;
      background: #fff url("../assets/img/empower/search-bg.png") no-repeat center;
      background-size: 100% 100%;
      box-shadow: 0 0.2rem 1rem 0 rgba(92, 85, 212, 0.10);
      display: flex;
      flex-direction: column;
      align-items: center;

      > .empower-title {
        width: 100%;
        font-size: 1.8rem;
        font-weight: 400;
        color: #333333;
        line-height: 2.6rem;
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        >.empower-title-img {
          height: 2.6rem;
          margin-right: 0.8rem;
        }
      }

      > .empower-search {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 28rem;

        > .clear-input {
          position: absolute;
          right: 5.4rem;
          width: 1.6rem;
          height: 1.6rem;
          margin-top: 1.18rem;
          cursor: pointer;
        }

        > input {
          width: 28rem;
          height: 4rem;
          background: #FFFFFF;
          border-radius: 0.2rem;
          border: 0.1rem solid $md-color-primary;
          padding: 0 1.6rem;
          caret-color: $md-color-primary;
          user-select: text;

          font-size: 1.6rem;
          color: #333333;
        }

        > input::-webkit-input-placeholder {
          color: #ccc;
        }

        > .empower-search-btn {
          margin-top: 3rem;
          cursor: pointer;
          width: 12rem;
          height: 3.6rem;
          background: $md-color-primary;
          border-radius: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 1.6rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 2.2rem;

          > img {
            width: 1.6rem;
            height: 1.82rem;
            margin-right: 0.8rem;
          }

          > .spin {
            -webkit-animation: fa-spin 2s infinite linear;
            animation: fa-spin 2s infinite linear;
          }
        }

        > .empower-search-btn:hover {
          opacity: 0.8;
        }
      }
    }

    > .empower-result {
      margin-top: 3rem;
      width: 32.7rem;

      > .result-title {
        height: 4.6rem;
        background: #FFFFFF;
        font-size: 1.6rem;
        color: #333333;
        line-height: 2.2rem;
        border-bottom: 1px solid rgba(204, 204, 204, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > .result-content-error {
        font-size: 1.4rem;
        font-weight: 400;
        color: #333333;
        line-height: 2rem;
        padding: 2.3rem 4rem 3rem;
        background: #FFFFFF;

        > span {
          color: #DA2121;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2rem;
        }
      }

      > .result-content-success {
        padding: 2.3rem 3.9rem 3rem;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;

        > div {
          margin-bottom: 2.4rem;
          font-size: 1.4rem;
          font-weight: 400;
          color: #333333;
          line-height: 2rem;

          > span {
            font-size: 1.4rem;
            font-weight: 400;
            color: $md-color-primary;
            line-height: 2rem;
          }
        }

        > img {
          width: 24.8rem;
          height: 35rem;
          background: url("../assets/img/empower/load.gif") no-repeat center;
          background-size: 20%;
        }
      }
    }
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}
</style>
